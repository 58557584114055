import React from 'react';

import { MdCheck, MdClose } from 'react-icons/md'

export const FlagComponentReadOnly = (props) => {
	const { flag, fontSize="1em"} = props;
	const colorOfIcon = flag?"green":"red";
	const Icon = flag?MdCheck:MdClose;
	
	return(
		<span onMouseDown ={(e) => e.preventDefault() } >
		<Icon fontSize={fontSize} color={colorOfIcon}></Icon>
		</span>
	)
}


