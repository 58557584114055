import React from 'react';

import { MdOutlineCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'

export const FlagComponent = (props) => {
	const { onClick, flag = false, fontSize="1.6em"} = props;
	const colorOfIcon = flag?"green":"grey";
	const Icon = flag?MdOutlineCheckBox:MdCheckBoxOutlineBlank;
	
	return(
		<span className="align-self-center" onMouseDown ={(e) => {e.preventDefault(); e.stopPropagation()} } >
		<Icon className="clickableValue" fontSize={fontSize} color={colorOfIcon}
		onClick={ (e) => {
			onClick(); e.preventDefault(); }}></Icon>
		</span>
	)
}


