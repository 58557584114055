import { requestFetchPunktemeldungen, receiveFetchedPunktemeldungen, fetchPunktemeldungenFailed } from "../veranstaltungen/actions";
import { BACKEND_BASE_URL, BACKEND_KAMMERDIENER_PREFIX } from "../../EivngCore";

const LOAD_PUNKTEMELDUNGEN_LIMIT = 256;

export const MAX_COUNT_PUNKTEMELDUNGEN = LOAD_PUNKTEMELDUNGEN_LIMIT;

export function fetchPunktemeldungen(vnr, bearer) {
  return async dispatch => {
		dispatch(requestFetchPunktemeldungen());
    console.log('being load Veranstaltungsdata from server');
    //  ("Authorization", "Basic " + btoa(username + ":" + password));
    
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${bearer}`);
    const url = `${BACKEND_BASE_URL}${BACKEND_KAMMERDIENER_PREFIX}/fobi/veranstalter/gemeldetepunkte?limit=${LOAD_PUNKTEMELDUNGEN_LIMIT}`
    try{
      const response = await fetch(url,{
          method: 'get',
          credentials: 'include',
          headers: headers			
        })
      const status = response.status
      //console.debug(status)
      switch(status){
        case 200:
            const json = await response.json()
            //console.debug('Fetched Data:', json)
            dispatch(receiveFetchedPunktemeldungen(vnr, json));
            break
        default:
          dispatch(fetchPunktemeldungenFailed(vnr, status));
      }
    }catch(err) {
      console.log('Fetch Punktemeldungen Error :-S', err);
      dispatch(fetchPunktemeldungenFailed(vnr, err));
    }
}
}
  