import React from 'react'
import i18next from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import { displayDialogQrcodeLinkToApp } from '../store/appNavigation/actions';
import QRCode from 'qrcode.react';

const getLocationWithoutParameters = () => {
	const currentUrl = new URL(window.location.href);
	return currentUrl.origin + currentUrl.pathname;
}
const linkToApp = getLocationWithoutParameters();
  
  // Verwendung der Funktion
 console.log(getLocationWithoutParameters());
  
const QRCodeLinkToAppDialog = (props) => {
	const { isOpen, onClose } = props
	return(
		<>
		<Modal centered={true} isOpen={isOpen} toggle={ () => onClose() }>
		<ModalHeader toggle={ () => onClose()}>
			Link zur Applikation
		</ModalHeader>
		<ModalBody className="backgroundColor">
			{/* <div className="lead text-center">{efn}</div> */}
			<div className="text-center h5" >
			{linkToApp}
			</div>
			<div className="text-center" >
					<QRCode className="m-4" value={linkToApp}/>
				
				<div className="lead">Link mit Kamera öffnen</div>
				<div className="lead">Zum Startbildschirm hinzufügen</div>
			</div>
		</ModalBody>
		<ModalFooter  className="backgroundColor">
		  <button type="button" className="btn btn-light btn-block" onClick = { (e) => onClose() }>{i18next.t('close')}</button>
		</ModalFooter>
	</Modal>
	</>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.appNavigation.displayDialogQrcodeLinkToApp
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onClose: () => dispatch(displayDialogQrcodeLinkToApp(false)),
})
export default connect(mapStateToProps, mapDispatchToProp)(QRCodeLinkToAppDialog)