export const PUSH_NOTIFICATION_DIALOG_SHOW = 'PUSH_NOTIFICATION_DIALOG_SHOW'
export const PUSH_NOTIFICATION_DIALOG_CLOSE = 'PUSH_NOTIFICATION_DIALOG_CLOSE'

// Action creator
// export const showPushNotificationDialog = (title, message, onConfirm) => {
// 	return { type: PUSH_NOTIFICATION_DIALOG_SHOW, title, message, onConfirm}
// }
export const closePushNotificationDialog = () => {
	return { type: PUSH_NOTIFICATION_DIALOG_CLOSE}
}
