
export const appScreens = { 
  startPage : "startPage", 
  veranstalterPage : 'veranstalterPage',
  veranstaltungPage : 'veranstaltungPage',
  setupEFNPage : 'setupEFNPage'
};

export const SET_SCREEN = 'SET_SCREEN'
export const SET_SCREEN_VERANSTALTUNG = 'SET_SCREEN_VERANSTALTUNG'
export const SET_SAVE_STATE_TO_LOCAL_STORAGE = 'SET_SAVE_STATE_TO_LOCAL_STORAGE';
export const DISPLAY_DIALOG_QRCODE_LINK_TO_APP = 'DISPLAY_DIALOG_QRCODE_LINK_TO_APP'


// Action creator
export const setScreen = (screen) => {
	return { type: SET_SCREEN, payload : screen }
}
export const setScreenVeranstaltung = (vnr) => {
	return { type: SET_SCREEN_VERANSTALTUNG, payload : vnr }
}
export const setSaveStateToLocalStorage = ( flagEnabled ) => {
	return { type: SET_SAVE_STATE_TO_LOCAL_STORAGE, payload : flagEnabled }
}
export const displayDialogQrcodeLinkToApp = ( flagDisplay ) => {
	return { type: DISPLAY_DIALOG_QRCODE_LINK_TO_APP, payload : flagDisplay }
}