import { combineReducers } from 'redux'
import { addVeranstaltungPanelReducer, veranstaltungenReducer } from './veranstaltungen/reducers'
import { addTeilnahmeReducer, teilnahmeMeldungenReducer, erfassteTeilnahmenReducer, qrReaderReducer } from './punkteMeldung/reducer'
import {FETCH_VERANSTALTUNG_REQUEST, FETCH_VERANSTALTUNG_REQUEST_SUCCESS, 
	FETCH_VERANSTALTUNG_REQUEST_FAILURE,
	FETCH_PUNKTEMELDUNGEN_REQUEST,
	FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS,
	FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE} from './veranstaltungen/actions'
import { appNavigationReducer } from './appNavigation/reducer';
import { confirmDialogReducer } from './confirmDialog/reducer';
import { pushNotificationDialogReducer } from './pushNotificationDialog/reducer';
import { alertsReducer } from './alerts/reducer';
import efnHolderReducer from './efnholder/reducers'
import notificationsReducer from './notifications/reducer'
import { EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST, 
	EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS, EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE, 
	EFN_HOLDER_SETUP_EFN_REQUESTED, EFN_HOLDER_SETUP_EFN_FAILURE, EFN_HOLDER_SETUP_EFN_SUCCESS
} from './efnholder/actions';
import { EFN_SUBSCRIPTION_RESQUEST_STARTED, EFN_SUBSCRIPTION_RESQUEST_FAILED, EFN_SUBSCRIPTION_RESQUEST_OK,
UNSUBSCRIBE_EFN_COMPLETED, UNSUBSCRIBE_EFN_FAILED, UNSUBSCRIBE_EFN_STARTED } from './notifications/actions';

/*
// Store Data shape
const initialState = {
	veranstaltungen : {},
	addVeranstaltungPanel : {},
	fetchCounter : 0,
	alerts : {},
	punkteMeldung: {} ---> punkteMeldungItitialState
	
};
const punkteMeldungItitialState = {
	teilnahmeMeldungen : {},
	addTeilnahme : {},
	erfassteTeilnahmen : {}
}
*/
const punkteMeldungReducer = combineReducers({
	teilnahmeMeldungen : 	teilnahmeMeldungenReducer,
	addTeilnahme : 			addTeilnahmeReducer,
	qrReader :				qrReaderReducer, 
	erfassteTeilnahmen : 	erfassteTeilnahmenReducer 
});

const fetchCounterReducer = (state = 0, action) => {
	switch (action.type){
		case FETCH_VERANSTALTUNG_REQUEST:
		case FETCH_PUNKTEMELDUNGEN_REQUEST:
		case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST:
		case EFN_HOLDER_SETUP_EFN_REQUESTED:
		case EFN_SUBSCRIPTION_RESQUEST_STARTED:
		case UNSUBSCRIBE_EFN_STARTED:
			return ++state;
		case FETCH_VERANSTALTUNG_REQUEST_SUCCESS:
		case FETCH_VERANSTALTUNG_REQUEST_FAILURE:
		case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS:	
		case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE:
		case FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS:
		case FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE:	
		case EFN_HOLDER_SETUP_EFN_SUCCESS:		
		case EFN_HOLDER_SETUP_EFN_FAILURE:
		case EFN_SUBSCRIPTION_RESQUEST_FAILED:
		case UNSUBSCRIBE_EFN_FAILED:
		case UNSUBSCRIBE_EFN_COMPLETED:
		case EFN_SUBSCRIPTION_RESQUEST_OK:		
				return --state;	
			default:
				return state;
			}
			
		};

const rootReducer = combineReducers({
	efnHolder : efnHolderReducer,
	alerts : alertsReducer , 
	appNavigation : appNavigationReducer,
	fetchCounter : fetchCounterReducer, 
	addVeranstaltungPanel : addVeranstaltungPanelReducer, 
	veranstaltungen : veranstaltungenReducer,
	confirmDialog: confirmDialogReducer,
	pushNotificationDialog: pushNotificationDialogReducer,
	punkteMeldung : punkteMeldungReducer,
	notifications : notificationsReducer
});
  
export default rootReducer