import React from 'react'

export const CSS_CLASSNAME_PANEL = 'bg-white p-3 rounded-lg border mb-4';
export const uuidv4 = require('uuid/v4');

export function notConnectedFunction() {
  console.debug("Not connected function")
}
export function createAnId(){
  return "wtncmp_" + uuidv4().replace(/-/g, '');
}
export const keinPunktekontoHinweis = (
  <p>Dies ist <strong>KEIN</strong> Fortbildungspunktekonto, sondern eine Information, 
  dass die Punktemeldung erfolgt ist und von Ihrer zuständigen Landesärztekammer auf Ihr Punktekonto gutgeschrieben werden kann.</p>
)