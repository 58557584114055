import { onRegistrationAction, onRegistrationErrorAction, onNotificationAction, registrationIdChanged } from "./actions"
import { fetchPunktemeldungenOfEFNHolder } from "../store/efnholder/actions"

const options = {
	"android": {
		// icon: 'ic_stat_iconandroid'
	},
	"browser": {
		// Browser wird nicht über cordova verwendet. Es wird die PWA Funktionalität verwendet
		applicationServerKey: "BEYXWYO30_9AsqQ8rX5nNXTOVdH3IdxLoYz1UFn-ymtaaQbSZ7Z-hEQn9ZtulIWWCGS7ZtyZMqJWDgYalEJOIzg"
	},
	"ios": {
			"sound": true,
			"vibration": true,
			"badge": true
	},
	"windows": {}
}

export class CordovaPushNotification {

	constructor(store, state){
		this.store = store
		if(state && state.notifications){
			this.registrationData = state.notifications.registrationData
		} 
	}

	onRegistartion(data){
		this.store.dispatch(onRegistrationAction(data))
		if(this.registrationData && 
			this.registrationData.registrationId !== data.registrationId) {
				this.store.dispatch(registrationIdChanged())
				navigator.notification.alert(`RegistrationId changed: ${data.registrationId}`, null, "RegistrationId", ["Ok"])
			}
		this.registrationData = data
		//navigator.notification.alert(`Completed: Registration ID: ${data.registrationId}`, null, "Registration", ["Ok"])
	}

	onRegistartionError(error){
		this.store.dispatch(onRegistrationErrorAction(error))
		//navigator.notification.alert(`Registration failed: ${error}`, null, "Registration failed", ["Ok"])
	}

	onNotification(data){
		data.onConfirm = null
		this.store.dispatch(onNotificationAction(data))
		const state = this.store.getState()
		if(state.appNavigation.currentScreen === 'setupEFNPage' && state.efnHolder.efn && state.efnHolder.authToken){
			this.store.dispatch(fetchPunktemeldungenOfEFNHolder(state.efnHolder.efn, state.efnHolder.authToken))
		}
		// navigator.notification.alert(
		// 	data.message,         // message
		// 	null,                 // callback
		// 	data.title,           // title
		// 	'Ok'                  // buttonName
		// 	);
	}
	
	hasPermission() {
		return new Promise( (resolve, reject ) => {
			try{
				window.PushNotification.hasPermission( (data) =>{
					resolve(data)
				} )
			}catch(error){
				reject(error)
			}
		})
	}
	initAndRegisterPushNotification() {
    return new Promise(
        (resolve, reject) => {
					if(	this.pushNotification ) resolve(this.registrationData)
					this.pushNotification = window.PushNotification.init(options)
					this.pushNotification.on('registration', (data) => {
						this.onRegistartion(data)
						resolve(data)
					})
					this.pushNotification.on('error', (error) => {
						this.onRegistartionError(error)
						reject(error)})
					this.pushNotification.on('notification', (data) => {
						this.onNotification(data)
					})
				}
    )
};
}

