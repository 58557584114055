import React from 'react'
import { connect } from 'react-redux'
import PunktemeldungListItem from './PunktemeldungListItem';

export const ListOfPunktemeldungen = (props) => {
	const {showLernerfolg, showTeilnahmedatum, 
		classNamePunktemeldungWithRefPunkte,
		punkteMeldungen, vnr} = props
	return(
		<div className = {props.className}>
			<ul className="list-group">
				{punkteMeldungen.map((punktemeldung, index) => <PunktemeldungLI key={index} vnr = {vnr}
				className = {punktemeldung.punkte_referent > 0?classNamePunktemeldungWithRefPunkte:""}
				showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} punktemeldung={punktemeldung}></PunktemeldungLI>)}
		
			</ul>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return{
		istInEingabe: ownProps.punktemeldung.efn === state.punkteMeldung.addTeilnahme[ownProps.vnr].efn
	}
}
const PunktemeldungLI = connect(mapStateToProps)(PunktemeldungListItem)