import React from 'react'
import { Alert } from 'reactstrap'
import i18next from 'i18next';
import { connect } from 'react-redux'

const KeinePunktemeldungen = (props) => {
	const {punktemeldungen} = props
	if(punktemeldungen.length > 0) return null
  return (
		<Alert color="" className="h5 text-center mt-5 mb-5 lead">
			{i18next.t('keine.punktemeldungen')}
		</Alert>
	);
}
const mapStateToProps = (state, ownProps) => (
	{
		punktemeldungen : state.efnHolder.punktemeldungen
	}
)
export default connect(mapStateToProps)(KeinePunktemeldungen);