import { recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd } from "../store/veranstaltungen/fobiAppBarcodeContentRecogniser";
import { fobiCore } from "../FobiCore";
import { recognizeEfnEinrichtungsCode } from "../efnholder/efnEinrichtungsCodeRecognizer";

export const defaultBarcodeScannerOptions = {
		preferFrontCamera : false, // iOS and Android
		showFlipCameraButton : true, // iOS and Android
		showTorchButton : true, // iOS and Android
		torchOn: false, // Android, launch with the torch switched on (if available)
		saveHistory: true, // Android, save scan history (default false)
		prompt : "Bitte den Barcode einscannen", // Android
		resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
		formats : "QR_CODE, DATA_MATRIX, CODE_128", // default: all but PDF_417 and RSS_EXPANDED
		orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
		disableAnimations : true, // iOS
		disableSuccessBeep: false // iOS and Android
}
const TITLE_BARCODE_VERWECHSELT = 	"Ups, Barcode verwechselt ?"

export const scanAddVeranstaltungBarcode = (changeVNR, showConfirmDialog) => {
	if( window.cordova ) {
		window.cordova.plugins.barcodeScanner.scan(
				function (result) {
					let title = "Unbekannter Barcode"
					let messageText = "Keine Veranstaltungedaten im Barcode gefunden. Bitte den Barcode mit VNR oder Veranstaltungsdaten einscannen."
					if( result.cancelled || result.text.length === 0 ) return
					if(fobiCore.isVNR(result.text)) changeVNR(result.text)
					else if( recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd(result.text) !== null)
						changeVNR(result.text)
					else{
						if(fobiCore.isEFN(result.text)){
							title = TITLE_BARCODE_VERWECHSELT
							messageText = "Barcode mit der Einheitlichen Forbildungsnummer wurde eingescannt. Bitte den Barcode mit VNR oder Veranstaltungsdaten einscannen."
						} else if(recognizeEfnEinrichtungsCode(result.text)){
							title = TITLE_BARCODE_VERWECHSELT
							messageText = "Barcode für den Teilnehmer mit EFN wurde eingescannt. Bitte den Barcode mit VNR oder Veranstaltungsdaten einscannen."
						}
						showConfirmDialog( title, messageText, null )
					}
				},
				function (error) {
						console.error("Scanning failed: " + error);
				},
				{ ...defaultBarcodeScannerOptions, 
						prompt : "Bitte den Barcode mit VNR, oder Veranstaltungsdaten einscannen", // Android
				}
		);
	}
}


export const scanEFNBarcode = (changeEFN, showConfirmDialog) => {
	if( window.cordova ) {
		window.cordova.plugins.barcodeScanner.scan(
				function (result) {
					if( result.cancelled || result.text.length === 0 ) return
					if(fobiCore.isEFN(result.text) || recognizeEfnEinrichtungsCode(result.text)) changeEFN(result.text)
					else{
						let title = "EFN nicht gefunden"
						let messageText = "Kein EFN Barcode. Bitte den Barcode mit EFN einscannen (FOBI Aufkleber, Arztausweis, etc.)"
						const vnrEinrichtungsCode = recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd(result.text)
						if(vnrEinrichtungsCode){
							title = TITLE_BARCODE_VERWECHSELT
							messageText = "Veranstaltungsbarcode wurde eingescannt. Bitte den EFN oder EFN- Einrichtungbarcode einscannen."
						}
					showConfirmDialog( title, messageText, null )
					}
				},
				function (error) {
						console.error("Scanning failed: " + error);
				},
				{ ...defaultBarcodeScannerOptions, 
						prompt : "Bitte den Barcode mit EFN einscannen", // Android
				}
		);
	}
}

