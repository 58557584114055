import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import './App.css';
import  WrappedStartPage from './StartPage';
import  EFNHolderPage  from './efnholder/EFNHolderPageConnector';
import  VeranstalterPage  from './VeranstalterPage';
import VeranstaltungPage  from './components/VeranstaltungPage';
import FetchingInProgress from './components/FetchingInProgress';
import { appScreens } from './store/appNavigation/actions'
import ConfirmDialog from './components/ConfirmDialog';
import PushNotificationDialog from './components/PushNotificationDialog';
import { applicationStarts } from './store/actions';
import HeaderAlert from './components/HeaderAlert';
import { ToastContainer }from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCodeLinkToAppDialog from './components/QRCodeLinkToAppDialog';

function App(props) {
  const {currentScreen, applicationStarts, state} = props
	useEffect(() => {
      applicationStarts(state)
  }, [applicationStarts, state]);
  let showScreen = null;
  switch(currentScreen){
    case appScreens.startPage:
      showScreen = <WrappedStartPage />
      break;
    case appScreens.veranstalterPage:
      showScreen = <VeranstalterPage />
      break;
    case appScreens.veranstaltungPage:
      showScreen = <VeranstaltungPage />
      break;
    case appScreens.setupEFNPage:
      showScreen = <EFNHolderPage />
      break;
  default:
        showScreen = <WrappedStartPage />
  }
  return (
    <>
    <FetchingInProgress/>
    <HeaderAlert/>
    <ConfirmDialog/>
    <PushNotificationDialog/>
    <QRCodeLinkToAppDialog/>
    <ToastContainer />
      {showScreen}
    </>
  );
}
const putStateToProps = (state) => {
	return { 
    currentScreen : state.appNavigation.currentScreen
  }
}

const putActionsToProps = (dispatch, ownProps) => {
	return {
		applicationStarts : () => dispatch(applicationStarts(ownProps.state))
	}
};

export default connect(putStateToProps, putActionsToProps)(App);
