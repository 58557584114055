export const ADD_VERANSTALTUNG_ALERT_SHOW = 'ADD_VERANSTALTUNG_ALERT_SHOW'
export const ADD_VERANSTALTUNG_ALERT_CLOSE = 'ADD_VERANSTALTUNG_ALERT_CLOSE'
export const EFN_HOLDER_ALERT_SHOW = 'EFN_HOLDER_ALERT_SHOW'
export const EFN_HOLDER_ALERT_CLOSE = 'EFN_HOLDER_ALERT_CLOSE'
export const ACTIVATE_NOTIFICATIONS_ALERT_SHOW = 'ACTIVATE_NOTIFICATIONS_ALERT_SHOW'
export const ACTIVATE_NOTIFICATIONS_ALERT_CLOSE = 'ACTIVATE_NOTIFICATIONS_ALERT_CLOSE'
export const DIMISS_HEADER_ALERT = 'DIMISS_HEADER_ALERT'
export const SHOW_HEADER_ALERT = 'SHOW_HEADER_ALERT'

// Action creator
export const addVeranstaltungShowAlert = (message) => {
	return { type: ADD_VERANSTALTUNG_ALERT_SHOW, message}
}
export const dimissHeaderAlert = () => {
	return { type: DIMISS_HEADER_ALERT }
}
export const addVeranstaltungDimissAlert = () => {
	return { type: ADD_VERANSTALTUNG_ALERT_CLOSE}
}
export const efnHolderShowAlert = (message) => {
	return { type: EFN_HOLDER_ALERT_SHOW, message}
}
export const efnHolderDimissAlert = () => {
	return { type: EFN_HOLDER_ALERT_CLOSE}
}
export const activateNotificationsShowAlert = (message="no message found", alertColor ="danger") => {
	return { type: ACTIVATE_NOTIFICATIONS_ALERT_SHOW, payload: { message, alertColor}}
}
export const showHeaderAlert = (message="no message found", alertColor ="danger") => {
	return { type: SHOW_HEADER_ALERT, payload: { message, alertColor}}
}
export const activateNotificationsDimissAlert = () => {
	return { type: ACTIVATE_NOTIFICATIONS_ALERT_CLOSE}
}