export const getDeviceId = async (videoInputDevices, facingMode) => {
  videoInputDevices = videoInputDevices.filter((deviceInfo) => deviceInfo.kind === "videoinput");

  if (videoInputDevices.length < 1) {
    throw new Error("No video input devices found");
  }

  const regex = facingMode === "environment" ? /rear|back|environment/gi : /front|user|face/gi;

  const devices = videoInputDevices.filter((videoDevice) => regex.test(videoDevice.label));

  if (!devices.length) {
    throw new Error("No video input devices found");
  }

  return devices[0].deviceId;
};

export const styles = {
  container: {
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  video: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    transform: undefined
  },
};
