import { BACKEND_BASE_URL, EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT, BACKEND_KAMMERDIENER_PREFIX } from "../../EivngCore"
import { FAILED_TO_FETCH_ERROR, INTERNET_CONNECTION_APPEARS_TO_BE_OFFLINE } from "../../CommonUtils"
import { efnHolderShowAlert } from "../alerts/actions"
import * as fetchUtil from "../../common/fetchUtils"

export const  EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST = "EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST"
export const  EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE = "EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE"
export const  EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS = "EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS"

export const  EFN_HOLDER_SETUP_EFN_REQUESTED = "EFN_HOLDER_SETUP_EFN_REQUESTED"
export const  EFN_HOLDER_SETUP_EFN_SUCCESS = "EFN_HOLDER_SETUP_EFN_SUCCESS"
export const  EFN_HOLDER_SETUP_EFN_FAILURE = "EFN_HOLDER_SETUP_EFN_FAILURE"

export const  EFN_HOLDER_SET_EFN= "EFN_HOLDER_SET_EFN"
export const  EFN_HOLDER_SET_AUTHTOKEN = "EFN_HOLDER_SET_AUTHTOKEN"
export const  EFN_HOLDER_EFN_ABMELDEN= "EFN_HOLDER_EFN_ABMELDEN"
export const  EFN_HOLDER_SET_VISIBLE_SCAN_MY_EFN_DIALOG= "EFN_HOLDER_SET_VISIBLE_SCAN_MY_EFN_DIALOG"


export function setVisibleScanMyEFNDialog(visible){
  return {type: EFN_HOLDER_SET_VISIBLE_SCAN_MY_EFN_DIALOG, payload : visible}
}
export function requestFetchPunktemeldungen(efn){
  return {type: EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST, payload : efn}
}
export function setupEFNRequested(efn){
  return {type: EFN_HOLDER_SETUP_EFN_REQUESTED, payload : efn}
}
export function setupEFNRequestedFailed(error){
  return {type: EFN_HOLDER_SETUP_EFN_FAILURE, payload : error}
}
export function receiveFetchedSetupEFNData(setupEFNData){
  return {type: EFN_HOLDER_SETUP_EFN_SUCCESS, payload: setupEFNData}
}
export function efnAbmelden(){
  return {type: EFN_HOLDER_EFN_ABMELDEN}
}
export function receiveFetchedPunktemeldungen(punktemeldungen){
  return {type: EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS, payload: punktemeldungen}
}
export function fetchPunktemeldungenFailed(error){
  return { type: EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE, payload: error}
}
export function setEFN(efn) {
  return { type: EFN_HOLDER_SET_EFN, payload: efn }
}
export function setAuthToken(authToken) {
  return { type: EFN_HOLDER_SET_AUTHTOKEN, payload: authToken }
}

export function fetchPunktemeldungenOfEFNHolder(efn, bearer) {
  return async dispatch => {
    dispatch(requestFetchPunktemeldungen(efn));
    console.debug('being load Punktemeldungen einer EFN from server');
    
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${bearer}`);
    try{  
      const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/teilnehmer/gemeldetepunkte/" + efn + "?limit=" + EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT,
      {
      method: 'get',
      credentials: 'include',
      headers: headers			
      })
      const status = response.status
      console.debug(status)
      switch(status){
        case 200:
            const jsonData = await response.json()
            console.debug('Fetched Data:', jsonData);
            dispatch(receiveFetchedPunktemeldungen(jsonData));
            break;
        // case 403:
        //   dispatch(fetchPunktemeldungenFailed(403));
        //   break;
        default:
          dispatch(fetchPunktemeldungenFailed(status));
      }
      
    }catch(err){
      console.error('EFN holder Punktemeldungen Fetch Error :-S', err);
      dispatch(fetchPunktemeldungenFailed(err));
    }
  }
}
export function setupEFN(efn, authToken) {
  return async dispatch => {
    dispatch(setupEFNRequested(efn));
    
    console.debug('being setup efn. get some data from server');
    try{  
      const authURL = `${BACKEND_BASE_URL}/fobi/teilnehmer-auth/jwt` 
      const result = await fetchUtil.fetchJsonWithBasicAuth(efn, authToken, authURL, "GET");
      if(result.isLeft){
        const statusCode = fetchUtil.getStatusOnLeft(result);
        dispatch(setupEFNRequestedFailed(statusCode));
        return;
      }
      const bearer = result.value.json.jwt;

      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', `Bearer ${bearer}`);
      const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/teilnehmer/setup/" + efn,
      {
      method: 'get',
      credentials: 'include',
      headers: headers			
      })
      const status = response.status
      console.debug(status)
      switch(status){
        case 200:
            const jsonData = await response.json()
            if(jsonData.efnDatamatrixBase64String === undefined) jsonData.efnDatamatrixBase64String="_undefined_"
            console.debug('setup EFN fetched Data:', jsonData)
            jsonData.bearer = bearer;
            dispatch(receiveFetchedSetupEFNData(jsonData))
            break;
        default:
          dispatch(setupEFNRequestedFailed(status))
      }
      
    }catch(err){
      console.error('EFN holder setup EFN fetch error', err)
      dispatch(setupEFNRequestedFailed(err))
      const strError = String(err)
      if(strError === FAILED_TO_FETCH_ERROR || strError === INTERNET_CONNECTION_APPEARS_TO_BE_OFFLINE){
        dispatch(efnHolderShowAlert("Verbindung zum Server konnte nicht aufgebaut werden. Ist die Internet- Verbindung verfügbar ?"))
      }else{
        dispatch(efnHolderShowAlert("EFN Einrichtung nicht erfolgreich: " + err))
      }
    }
  }
}

