import { connect } from 'react-redux'
//import  moment  from 'moment';
import { teilnehmerRefPunkteDec, teilnehmerRefPunkteInc, teilnehmerRefPunkteSet } from '../../store/punkteMeldung/actions';
import  ReferentenpunkteComponent  from './ReferentenpunkteComponent';

const mapStateToProps = (state, ownProps) => ({
	punkte: state.punkteMeldung.erfassteTeilnahmen[ownProps.vnr][ownProps.efn].punkte_referent
})
const mapDispatchToProps = (dispatch, ownProps) => ({
	increasePunkte : () => dispatch(teilnehmerRefPunkteInc(ownProps.vnr, ownProps.efn)),
	decreasePunkte: () => dispatch(teilnehmerRefPunkteDec(ownProps.vnr, ownProps.efn)),
	setPunkte : () => dispatch(teilnehmerRefPunkteSet(ownProps.vnr, ownProps.efn, 0))
})
const ErfassteTeilnahmeReferentenpunkte =  connect(mapStateToProps, mapDispatchToProps)(ReferentenpunkteComponent)

export default ErfassteTeilnahmeReferentenpunkte