import { 
	CONFIRM_DIALOG_CLOSE, CONFIRM_DIALOG_SHOW 
} from "./actions";
import { TEILNEHMER_MELDEN_REQUESTED } from "../punkteMeldung/teilnehmermeldenActionCreator";

const initialState = {
	isOpen : false,
	title : "Rückfrage",
	onConfirm : () => console.log("confirmed"),
	message : "Der Text der Message erscheint hier"
}

export const confirmDialogReducer = (state = initialState, action) => {
	switch(action.type){
		case TEILNEHMER_MELDEN_REQUESTED:
				return {...state, isOpen : false}
		case CONFIRM_DIALOG_CLOSE:
			return {...state, isOpen : false}
		case CONFIRM_DIALOG_SHOW:
			return {...state, isOpen : true, title : action.title, message : action.message, onConfirm : action.onConfirm}
		default: 
		return state
	}
}