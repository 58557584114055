import {ADD_VERANSTALTUNG_ALERT_SHOW, ADD_VERANSTALTUNG_ALERT_CLOSE, 
	ACTIVATE_NOTIFICATIONS_ALERT_SHOW, ACTIVATE_NOTIFICATIONS_ALERT_CLOSE, SHOW_HEADER_ALERT,
	EFN_HOLDER_ALERT_CLOSE, DIMISS_HEADER_ALERT, EFN_HOLDER_ALERT_SHOW } from './actions'
import { EFN_SUBSCRIPTION_RESQUEST_FAILED, EFN_SUBSCRIPTION_RESQUEST_STARTED, UNSUBSCRIBE_EFN_STARTED } from '../notifications/actions'
import { EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE, 
	EFN_HOLDER_SETUP_EFN_FAILURE, EFN_HOLDER_SETUP_EFN_REQUESTED,
	EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST, 
	EFN_HOLDER_EFN_ABMELDEN} from '../efnholder/actions'
import { FETCH_VERANSTALTUNG_REQUEST, FETCH_VERANSTALTUNG_REQUEST_FAILURE, FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE, FETCH_PUNKTEMELDUNGEN_REQUEST } from '../veranstaltungen/actions'
import { messageOn403 } from '../../efnholder/EFNHolderAlert'
import { messageOn401EFNHolder, messageOn401VNR } from '../../components/HeaderAlert'
import { ACTIVATE_NOTIFICATIONS_STARTED, ACTIVATE_NOTIFICATIONS_PERMISSION_NOT_GRANTED } from '../notifications/actions'
import { TEILNEHMER_MELDEN_FAILED } from '../punkteMeldung/teilnehmermeldenActionCreator'
import { /* CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION, */ CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION_ERROR } from '../../cordova/actions'

const initialState = {
	addVeranstaltungAlertIsOpen : false,
	addVeranstaltungAlertMessage : "Some error ...",
	headerAlertIsOpen : false,
	headerAlertMessage : "test error ...",
	headerAlertColor : "danger",
	efnHolderAlertIsOpen : false,
	efnHolderAlertMessage : "test error ...", 
	activateNotificationsAlertIsOpen : false,
	activateNotificationsAlertMessage : "default error activate notifications ...",
	activateNotificationsAlertColor : "danger" 
}

export const alertsReducer = (state = initialState, action) => {
	switch(action.type){
		
		case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE:
		case EFN_SUBSCRIPTION_RESQUEST_FAILED:
			if(action.payload === 401) 
				return {...state, headerAlertIsOpen : true, headerAlertMessage : messageOn401EFNHolder, headerAlertColor : "danger"}
			else return state
		case EFN_HOLDER_SETUP_EFN_FAILURE:	
			if(action.payload === 403 || action.payload === 401) 
				return {...state, efnHolderAlertIsOpen : true, efnHolderAlertMessage : messageOn403}
			else return state

		case ADD_VERANSTALTUNG_ALERT_SHOW:
				return {...state, addVeranstaltungAlertIsOpen : true, addVeranstaltungAlertMessage : action.message}

		case ADD_VERANSTALTUNG_ALERT_CLOSE:
		case FETCH_VERANSTALTUNG_REQUEST:
			return {...state, addVeranstaltungAlertIsOpen : false, headerAlertIsOpen : false}

		case FETCH_PUNKTEMELDUNGEN_REQUEST:
			return {...state, headerAlertIsOpen : false}

		case FETCH_VERANSTALTUNG_REQUEST_FAILURE:	
		case FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE:
		case TEILNEHMER_MELDEN_FAILED:	
				if(action.payload === 401) 
				return {...state, headerAlertIsOpen : state.addVeranstaltungAlertIsOpen?false:true, headerAlertMessage : messageOn401VNR, headerAlertColor : "danger"}
			else return state
		
		case 	DIMISS_HEADER_ALERT:
			return {...state, headerAlertIsOpen : false}

		case ACTIVATE_NOTIFICATIONS_ALERT_CLOSE:
		case EFN_SUBSCRIPTION_RESQUEST_STARTED:
		case UNSUBSCRIBE_EFN_STARTED:
		case ACTIVATE_NOTIFICATIONS_STARTED:
		case EFN_HOLDER_EFN_ABMELDEN:
			return {...state, activateNotificationsAlertIsOpen : false, headerAlertIsOpen : false}
		
		case ACTIVATE_NOTIFICATIONS_PERMISSION_NOT_GRANTED:
			return {...state, activateNotificationsAlertIsOpen : true, 
				activateNotificationsAlertMessage : "Berechtigung für Benachrichtigungen wurde nicht erteilt."}

		case ACTIVATE_NOTIFICATIONS_ALERT_SHOW:
				return {...state, activateNotificationsAlertIsOpen : true, activateNotificationsAlertMessage : action.payload.message,
					activateNotificationsAlertColor : action.payload.alertColor}

		case SHOW_HEADER_ALERT:
				return {...state, headerAlertIsOpen : true, headerAlertMessage : action.payload.message,
					headerAlertColor : action.payload.alertColor}
		// case CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION:
		// 	return {...state, headerAlertIsOpen : true, headerAlertMessage : `Registriert für Pushbenachrichtigung. registrationId: ${action.payload.registrationId}`,
		// 	headerAlertColor : 'success'}
		case CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION_ERROR:
				return {...state, headerAlertIsOpen : true, headerAlertMessage : `Registrieren für Pushbenachrichtigung ist fehlgeschlagen. Error: ${action.payload.message}`,
				headerAlertColor : 'danger'}

		case EFN_HOLDER_ALERT_SHOW:
				return {...state, efnHolderAlertIsOpen : true, efnHolderAlertMessage : action.message}
	
		case EFN_HOLDER_ALERT_CLOSE:
		case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST:	
		case EFN_HOLDER_SETUP_EFN_REQUESTED:	
			return {...state, efnHolderAlertIsOpen : false, headerAlertIsOpen : false}

		default: 
		return state
	}
}