import { APPLICATION_STARTS } from "../actions"
import { EFN_SUBSCRIPTION_RESQUEST_OK, EFN_SUBSCRIPTION_RESQUEST_FAILED, 
	PUSH_NOTIFICATION_SET_SUBSCRIPTION, /* PUSH_NOTIFICATION_UNSET_SUBSCRIPTION, */
	UNSUBSCRIBE_EFN_COMPLETED } from "./actions"
import { CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION, CORDOVA_PUSH_NOTIFICATIONS_REGISTRATIONID_CHANGED } from "../../cordova/actions"

export const isPushNotificationsSupported = () => ('Notification' in window && 'PushManager' in window)
export const isUseCordovaPushNotification = () => (window.cordova && 'browser' !== window.cordova.platformId?true:false)
//export const isUseCordovaPushNotification = () => (window.cordova?true:false)

const initialState = {
	useCordovaPushNotification : isUseCordovaPushNotification(),
	supported : isPushNotificationsSupported(),
	pushNotificationSubscription : null,
	efnSubscriptionId : null,
	resubscribeEfnRequired : false,
	registrationData : null
}

const notificationsReducer = (state = initialState, action) => {
	switch(action.type){
		case PUSH_NOTIFICATION_SET_SUBSCRIPTION:
			return {...state, pushNotificationSubscription : action.payload}
		// case PUSH_NOTIFICATION_UNSET_SUBSCRIPTION:
		// 	return {...state, pushNotificationSubscription : null}
		case EFN_SUBSCRIPTION_RESQUEST_OK:
			return {...state, efnSubscriptionId : action.payload, resubscribeEfnRequired: false}
		case CORDOVA_PUSH_NOTIFICATIONS_REGISTRATIONID_CHANGED:
			if( state.notifications.efnSubscriptionId ) return {...state, resubscribeEfnRequired: true}
			return state
		case EFN_SUBSCRIPTION_RESQUEST_FAILED:
		case UNSUBSCRIBE_EFN_COMPLETED:	
			return {...state, efnSubscriptionId : null}
		case APPLICATION_STARTS:
			return {...state, supported : isPushNotificationsSupported(), useCordovaPushNotification : isUseCordovaPushNotification()}
		case CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION:
			return {...state, registrationData: action.payload}	
		default: 
		return state
	}
}

export default notificationsReducer