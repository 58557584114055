import React, { useState, useEffect, useRef } from 'react';
import MultiFormatReader from "./MultiFormatReader";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Alert } from 'reactstrap';
import { connect, useSelector } from 'react-redux'
import { fobiCore } from '../FobiCore'
import { addTeilnahmeQRReaderToggleMultiscanMode, addTeilnahmeQrCodeEFNScanned } from '../store/punkteMeldung/actions';
import useDebouncedToast from './DebouncedToast';
import  moment  from 'moment';
import { CSS_CLASSNAME_PANEL } from '../EIVCommons';
import { getDeviceId } from './MultiFormatReaderUtils';
import { Howl } from 'howler';

const soundScanOk = new Howl({
  // src: ['./sounds/message-124468.mp3'] // Pfad zum Sound-File
  src: ['./sounds/camera-13695.mp3'] // Pfad zum Sound-File
});
const soundBereitsErfasst = new Howl({
  src: ['./sounds/rclick-13693.mp3'] // Pfad zum Sound-File
});
const soundScanNOk = new Howl({
  src: ['./sounds/stop-13692.mp3'] // Pfad zum Sound-File
});


const QRCodeScannerDialog = ({ isOpen, toggle, hideDialog, veranstaltung, applyScannedEFN, multiscanMode, toggleMultiscanMode, addTeilnahmeState }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  // const [torch, setTorch] = useState(false);
  const [showCamera, setShowCamera] = useState(true);
	const debouncedToast = useDebouncedToast(2000);
  const erfassteTeilnahmen = useSelector((state) => state.punkteMeldung.erfassteTeilnahmen[veranstaltung.vnr]);
  // console.log(veranstaltung);

  let multiscan = useRef(multiscanMode);
  let erfassteT = useRef(erfassteTeilnahmen);
  erfassteT.current = erfassteTeilnahmen?erfassteTeilnahmen:[];
  useEffect(() => {
    const fetchDevices = async () => {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);
      if (videoDevices.length > 0) {
        try{
          const deviceId = await getDeviceId(mediaDevices, facingMode);
          setSelectedDevice(deviceId);
        }catch(e){
          setSelectedDevice(videoDevices[0].deviceId);
        }
      }
    };

    fetchDevices();
  }, [facingMode]);
  
  useEffect(() => {
    // console.log("multiscanMode hat sich geändert:", multiscanMode);
    multiscan.current = multiscanMode;
  }, [multiscanMode]);

  useEffect(() => {
    // console.log("erfassteTeilnahmen hat sich geändert:", JSON.stringify(erfassteTeilnahmen));
    erfassteT.current = erfassteTeilnahmen;
  }, [erfassteTeilnahmen]);
  
  // console.log("------------> multiscanMode %s", multiscanMode);

  const onQRScanResult = (text, applied = false) => {
    // console.log("onQRScanResult -> multiscanMode", multiscanMode);
    // console.log("onQRScanResult -> multiscan.current", multiscan.current);
    if (fobiCore.isEFN(text)) {
      if(applied){
        debouncedToast(`EFN: ${text} erfasst`, "success", () => soundScanOk.play());
      }else{
        debouncedToast(`EFN: ${text} bereits erfasst`, "info", () => soundBereitsErfasst.play());
      }
      // Verwenden Sie direkt multiscanMode anstelle der mode-Konstante
      if (!multiscan.current) { toggle(); }
    } else {
      debouncedToast(`keine EFN erkannt`, "warning", () => soundScanNOk.play());
      // console.log(`keine EFN erkannt text: ${text}`);
      
    }
  };
  
  const handleScan = (data) => {
    if (data && data.text ) {
      const efn = fobiCore.findEFNInText(data.text);
      let applied = false;
      if(efn){
        if( !erfassteT.current[efn] ){
          applied = true;
          applyScannedEFN(efn, addTeilnahmeState);
        }
        onQRScanResult(efn, applied);
      }else{
        onQRScanResult(data.text, applied);
      }
    }
  };
  const handleToggleMode = () => {
    toggleMultiscanMode();
  };

  const refreshCameraView = () => {
    setShowCamera(false); setTimeout( () => setShowCamera(true), 30);
  }


  const toggleFacingMode = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
    refreshCameraView();
  };
  
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
      Teilnehmer mit EFN erfassen 
      </ModalHeader>
      <ModalBody>
        <small>
          <VeranstaltungsItem small veranstaltung={veranstaltung} />
        </small>
       
       { multiscanMode &&
       <Alert color="primary" >
          Multiscan- Modus. Mehrere EFNs nacheinander erfassen.
     </Alert>
       }
       { !multiscanMode &&
       <Alert color="secondary" >
       Einzel- Modus. Bitte EFN scannen.
     </Alert>
       }
       <Button color="secondary" className="py-2" size="sm" outline block onClick={handleToggleMode}>Modus umschalten</Button>
       { showCamera &&
          <MultiFormatReader
            onScan={handleScan}
            constraints={{ deviceId: selectedDevice, facingMode }}
          />
        }
        {devices.length > 1 && (
          <FormGroup>
            <Label for="deviceSelect">Kamera auswählen</Label>
            <Input
              type="select"
              name="deviceSelect"
              defaultValue={selectedDevice}
              id="deviceSelect"
              onChange={(e) => {
                setSelectedDevice(e.target.value); refreshCameraView();
                }}
            >
              {devices.map((device, index) => (
                <option key={index} value={device.deviceId}>{device.label || `Kamera ${index + 1}`}</option>
              ))}
            </Input>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
   
        <Button color="primary" block onClick={toggleFacingMode}>Front-/Rückkamera wechseln</Button>
        {/* <Button color="primary" onClick={toggleTorch}>Taschenlampe {torch ? 'ausschalten' : 'einschalten'}</Button> */}
        <Button color="secondary" onClick={toggle}>Schließen</Button>
      </ModalFooter>
    </Modal>
  );
};
const mapStateToProps = (state, ownProps) => { 
	return {
		multiscanMode: state.punkteMeldung.qrReader.multiscanMode,
    addTeilnahmeState: state.punkteMeldung.addTeilnahme[ownProps.veranstaltung.vnr]
	}
}
function VeranstaltungsItem(props){
	const {veranstaltung, small} = props;
  const themaClassName=small?"font-weight-bold":"h5";
	const endeZeit = moment(veranstaltung.beginn).format('DD.MM.YYYY') !== moment(veranstaltung.ende).format('DD.MM.YYYY')?
		moment(veranstaltung.ende).format('DD.MM.YYYY HH:mm'):moment(veranstaltung.ende).format('HH:mm')
	return (<div className={`clickableValue container-fluid ${CSS_CLASSNAME_PANEL}`}>
			<div>{veranstaltung.vnr}</div>
			<div className={themaClassName}>{veranstaltung.thema}</div>
			<div>{veranstaltung.unterthema}</div>
			<div className="row text-muted ">
			<div className="col-12 col-sm-7 col-md-6 col-lg-8">{moment(veranstaltung.beginn).format('DD.MM.YYYY HH:mm')} - {endeZeit}</div>
			
</div>			
					
		{/* <span>{countErfasste}</span>
		<span>{gemeldeteTeilnehmer}</span> */}
	</div>);

}
const mapDispatchToProp = (dispatch, ownProps) => ({
  applyScannedEFN: (qrScanResult, addTeilnahmeState) => dispatch(addTeilnahmeQrCodeEFNScanned(ownProps.veranstaltung.vnr, qrScanResult, addTeilnahmeState)),
	toggleMultiscanMode: () => dispatch(addTeilnahmeQRReaderToggleMultiscanMode()),
	
})

export default connect(mapStateToProps, mapDispatchToProp)(QRCodeScannerDialog);
