import React from 'react'
import {Alert} from 'reactstrap'
import {connect} from 'react-redux'
import { dimissHeaderAlert } from '../store/alerts/actions'

export const messageOn401EFNHolder ="Die EFN Autorisierung ist fehlgeschlagen. Bitte die EFN abmelden und erneut mit dem aktuellen Kennwort einrichten."
export const messageOn401VNR ="Die Autorisierung für VNR ist fehlgeschlagen. Bitte die Veranstaltung entfernen und erneut mit dem aktuellen Kennwort hinzufügen."

const HeaderAlert = (props) => {
	const {isOpen = false, onDimiss = null, alertColor = "danger",
	message = messageOn401EFNHolder } = props
	return(
		<Alert isOpen={isOpen} color={alertColor} toggle={ () => onDimiss()} >{message}</Alert>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.alerts.headerAlertIsOpen,
		message: state.alerts.headerAlertMessage,
		alertColor: state.alerts.headerAlertColor
		
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onDimiss: () => dispatch(dimissHeaderAlert()),
})
export default connect(mapStateToProps, mapDispatchToProp)(HeaderAlert)