import React from 'react'
import {Alert} from 'reactstrap'
import {connect} from 'react-redux'
import { activateNotificationsDimissAlert } from '../store/alerts/actions'

const ActivateNotificationsAlert = (props) => {
	const {isOpen = false, onDimiss = null, className, 
		alertColor ="danger", message = "default message" } = props
	return(
		<Alert className={className} color={alertColor} isOpen={isOpen} toggle={ () => onDimiss()} >{message}</Alert>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.alerts.activateNotificationsAlertIsOpen,
		message: state.alerts.activateNotificationsAlertMessage,
		alertColor: state.alerts.activateNotificationsAlertColor
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onDimiss: () => dispatch(activateNotificationsDimissAlert()),
})
export default connect(mapStateToProps, mapDispatchToProp)(ActivateNotificationsAlert)