import React from 'react'

import moment from 'moment';
import { isShowTeilnahmedatum } from '../components/TeilnehmerMelden/teilnahmedatum';
import { fobiCore } from '../FobiCore';

const style = {
	fontSize : {fontSize: "89%"},
	gesamtPunkte : { minWidth : "3em"}
}
const Teilnahmedatum = (props) => {
	const { punktemeldung } = props
	if( !isShowTeilnahmedatum(punktemeldung) ) return null
	const teilnahmedatum = punktemeldung.teilnahmedatum === null?null:moment(punktemeldung.teilnahmedatum).format('DD.MM.YYYY')
	return(
		<div title="Teilnahmedatum">
		 {teilnahmedatum}
		</div>
	)
}
const PunktemledungTimestamp = (props) => {
	const { punktemeldung } = props
	return(
			<span className="font-weight-normal badge badge-info" style={style.fontSize} title="Meldedatum" >{moment(punktemeldung.last_modified).format('DD.MM.YYYY HH:mm:ss')}</span>
	)
}
const BasisPunkte = (props) => {
	const { punktemeldung } = props
	if(punktemeldung.punkte_basis_flag === null) return null
	return(
		<span className="badge badge-light" >Basispunkte: {punktemeldung.punkte_basis_flag?
			punktemeldung.punkte_basis:0}</span>
	)
}
const LernerfolgPunkte = (props) => {
	const { punktemeldung } = props
	if(!fobiCore.isShowLernerfolg(punktemeldung)) return null
	if(punktemeldung.punkte_lernerfolg_flag === null) return null
	return(
		<span className="badge badge-light">Lernerfolg: {punktemeldung.punkte_lernerfolg_flag?
			punktemeldung.punkte_lernerfolg:0} </span>
	)
}
const Referentenpunkte = (props) => {
	const { punktemeldung } = props
	if(punktemeldung.punkte_referent === null) return null
	return(
		<span className="badge badge-light">Ref/Mod/WL Pkte.: {punktemeldung.punkte_referent}</span>
	)
}
const VNR = (props) => {
	const { punktemeldung } = props
	return(
		<div title="Veranstaltungsnummer (VNR)">{punktemeldung.vnr}</div>
	)
}
const BeginnEndeDatum = (props) => {
	const { punktemeldung : data } = props
	const endeZeit = moment(data.beginn).format('DD.MM.YYYY') !== moment(data.ende).format('DD.MM.YYYY')?
		moment(data.ende).format('DD.MM.YYYY HH:mm'):moment(data.ende).format('HH:mm')
	return(
		<span className="text-muted" title="Beginn und Ende der Veranstaltung">{moment(data.beginn).format('DD.MM.YYYY HH:mm')} - {endeZeit}</span>
	)
}

const PunkteGesamt = (props) => {
	const { punktemeldung } = props
	let punkte = 0
	if(punktemeldung.punkte_basis_flag) punkte+=punktemeldung.punkte_basis
	if(punktemeldung.punkte_lernerfolg_flag) punkte+=punktemeldung.punkte_lernerfolg
	if(punktemeldung.punkte_referent) punkte+=punktemeldung.punkte_referent

	return(	
			<>
			<div className="h1 d-inline">{punkte}</div>
			<div className="">Pkt.</div>
			</>
	)
}
const PunktemeldungListItem = (props) => {
	const {punktemeldung, liKey } = props
	return(
		<li className="list-group-item" key={liKey}>
			<div className="row ">
				<div className="col">
					<PunktemledungTimestamp punktemeldung={punktemeldung}/>
				</div>
				<div className="col align-self-end text-right" >
					<Teilnahmedatum punktemeldung={punktemeldung}/>
				</div>
			</div>
			<div className="row">
				{/* <table className="ml-3"> */}
					{/* <tr className=""> */}
						<div className="col-10 col-md-11">
							<div className="row">
								<div className="col">
									<div className="h5 mb-1">{punktemeldung.thema}</div>
									<div>{punktemeldung.unterthema}</div>
									<div className="text-muted">Kat. ({punktemeldung.kategorie}) <BeginnEndeDatum punktemeldung={punktemeldung}/></div>
								</div>
							</div>
						</div>
						<div className="col-2 col-md-1 pl-2 pr-2 text-center">
							<PunkteGesamt punktemeldung = {punktemeldung} />
						</div>
					{/* </tr> */}
				{/* </table> */}
			</div>
			<div className="row text-secondary">
				<div className="col">
					<BasisPunkte punktemeldung={punktemeldung}/> <LernerfolgPunkte punktemeldung={punktemeldung}/> <Referentenpunkte 
					punktemeldung={punktemeldung}/>
				</div>
				<div className="col align-self-end text-right">
					<VNR punktemeldung={punktemeldung}/>
				</div>
			</div>	
		</li>
	)
}

export default PunktemeldungListItem