//import React from 'react';
import { connect } from 'react-redux'
import { setScreen } from '../store/appNavigation/actions';
import { EFNHolderPage } from './EFNHolderPage';
import { efnAbmelden } from '../store/efnholder/actions';
import { unsubscribeEFN } from '../store/notifications/actions';

const mapStateToProps = (state, ownProps) => (
	{
		efn: state.efnHolder.efn,
		authToken: state.efnHolder.authToken,
		subscriptionId: state.notifications.efnSubscriptionId,
		efnSetupCompleted : state.efnHolder.efnSetupCompleted
	}
)
const putActionsToProps = dispatch => {
	return {
		efnAbmelden : (efn, authToken, subscriptionId) => {
			const showAlerts = false
			dispatch(unsubscribeEFN(efn, authToken, subscriptionId, showAlerts))
			dispatch(efnAbmelden())
		},
		setScreen : (screen) => dispatch(setScreen(screen))
	}
};

export default connect(mapStateToProps, putActionsToProps)(EFNHolderPage);