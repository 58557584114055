import { isUseCordovaPushNotification } from "./notifications/reducer"
import { pmSubscribe, fcmSubscribeEFN } from './notifications/actions'

/*
 * action types
 */
export const APPLICATION_STARTS = 'APPLICATION_STARTS'

/*
 * action creators
 */
export const applicationStarts = (state) => {
  return async dispatch => {
      try{  
				
				if(state.notifications.resubscribeEfnRequired){
					if(state.notifications.efnSubscriptionId){
						if(isUseCordovaPushNotification === true){
							dispatch(fcmSubscribeEFN(state.efnHolder.efn, state.efnHolder.authToken, state.notification.registrationData.registrationsId))
							navigator.notification.alert(`Resubscribe EFN (FCM)`, null, "Resubscription required", ["Ok"])
						}else{
							dispatch(pmSubscribe(state.efnHolder.efn, state.efnHolder.authToken))
							navigator.notification.alert(`Resubscribe EFN WebPush`, null, "Resubscription required", ["Ok"])
						}
					}
				}
				dispatch({ type: APPLICATION_STARTS})
				
    }catch(err){
      console.error('Error on applicationStarts', err);
    }
	}
}