import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { thunk } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import throttle from 'lodash/throttle';
import {saveState, loadState} from "./localStorage";
import { eivngCore } from './EivngCore'
import './eiv.css';
import './i18n';

import { createStore,  applyMiddleware } from 'redux'
import rootReducer from './store/reducers'
import { CordovaPushNotification } from './cordova/cordovaPushNotification';
import { preStartStatePreparation } from './prestartPreparation';
import * as ReactDOMClient from 'react-dom/client';
const persistedState = loadState();
if(persistedState) preStartStatePreparation(persistedState)
const loggerMiddleware = createLogger();

const store = createStore(rootReducer, persistedState, applyMiddleware(
	thunk, // lets us dispatch() functions
	loggerMiddleware // neat middleware that logs actions
));

//window.myStore = store;
const renderReactDom = () => {
	const container = document.getElementById('root');
	const root = ReactDOMClient.createRoot(container);
	root.render(
	<Provider store={store}>
			<App state = {store.getState()}/>
	</Provider>);
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
		eivngCore.cordovaPushNotification = new CordovaPushNotification(store, persistedState)
		if('browser' !== window.cordova.platformId){
			if(eivngCore.cordovaPushNotification.registrationData) eivngCore.cordovaPushNotification.initAndRegisterPushNotification()
		} 
		renderReactDom();
  }, false);
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if(!window.cordova || 'browser' === window.cordova.platformId){
	serviceWorker.register();
}

store.subscribe(throttle(() => { 
	const state = store.getState()
	if(state.appNavigation.saveStateToLocalStorage) saveState(state);
}, 1000));

