import React, { useState } from 'react'
import i18next from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import { setVisibleScanMyEFNDialog } from '../store/efnholder/actions';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';

const ScanMyEFNDialog = (props) => {
	const { isOpen, onClose, efn, efnDataMatrixBase64String } = props
	const [ showQRCode, setShowQRCode ] = useState(true)
	return(
		<>
		<Modal centered={true} isOpen={isOpen} toggle={ () => onClose() }>
		<ModalHeader toggle={ () => onClose()}>
			Einheitliche Fortbildungsnummer (EFN)
		</ModalHeader>
		<ModalBody className="backgroundColor">
			{/* <div className="lead text-center">{efn}</div> */}
			<div className="text-center">
				<Barcode value={efn} />
			</div>
			<div className="text-center" >
				{showQRCode &&
					<QRCode className="m-4" onClick={ () => setShowQRCode(!showQRCode)} value={efn}/>
				}
				{!showQRCode &&
					<img className="m-4" onClick={ () => setShowQRCode(!showQRCode)} alt="Datamatrix" src={efnDataMatrixBase64String}/>
				}
				<div className="lead">zur Erfassung einscannen</div>
			</div>
		</ModalBody>
		<ModalFooter  className="backgroundColor">
		  <button type="button" className="btn btn-light btn-block" onClick = { (e) => onClose() }>{i18next.t('close')}</button>
		</ModalFooter>
	</Modal>
	</>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.efnHolder.showScanMyEFNDialog,
		efn: state.efnHolder.efn,
		efnDataMatrixBase64String: state.efnHolder.efnDatamatrixBase64String
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onClose: () => dispatch(setVisibleScanMyEFNDialog(false)),
})
export default connect(mapStateToProps, mapDispatchToProp)(ScanMyEFNDialog)