import { PUSH_NOTIFICATION_DIALOG_CLOSE, PUSH_NOTIFICATION_DIALOG_SHOW } from "./actions";
import { CORDOVA_PUSH_NOTIFICATIONS_ON_NOTIFICATION } from "../../cordova/actions";

const initialState = {
	isOpen : false,
	title : "Title der Pushbenachrichtigung",
	onConfirm : () => console.log("confirmed"),
	message : "Text der Pushbenachrichtigung"
}

export const pushNotificationDialogReducer = (state = initialState, action) => {
	switch(action.type){
		case PUSH_NOTIFICATION_DIALOG_CLOSE:
			return {...state, isOpen : false}
		case PUSH_NOTIFICATION_DIALOG_SHOW:
			return {...state, isOpen : true, title : action.title, message : action.message, onConfirm : action.onConfirm}
		case CORDOVA_PUSH_NOTIFICATIONS_ON_NOTIFICATION:
			return {...state, isOpen : true, title : action.payload.title, message : action.payload.message, onConfirm : action.payload.onConfirm}
		default: 
		return state
	}
}