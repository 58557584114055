import { ADD_TEILNAHME_CHANGE_EFN, 
	ADD_TEILNAHME_TOGGLE_PUNKTE_BASIS, 
	ADD_TEILNAHME_TOGGLE_PUNKTE_LERNERFOLG,
	ADD_TEILNAHME_REF_PUNKTE_CHANGE, 
	ADD_TEILNAHME_EFN_QRCODE_SCANNED,
	ADD_TEILNAHME_QR_READER_TOGGLE_MULTISCAN_MODE,
	ADD_TEILNAHME_REF_PUNKTE_DECREASE, 
	ADD_TEILNAHME_REF_PUNKTE_INCREASE, ADD_TEILNAHME_HINZUGUEGEN, PUNKTEMELDUNG_REMOVE_TEILNEHMER,
	PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_BASIS, PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_LERNERFOLG,
	PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_CHANGE, PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_DECREASE, 
	PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_INCREASE, ADD_TEILNAHME_CHANGE_TEILNAHMEDATUM, 
	PUNKTEMELDUNG_TEILNEHMER_CHANGE_TEILNAHMEDATUM,
	ADD_TEILNAHME_SANITIZE_EFNS
 } from "./actions";
import { fobiCore } from "../../FobiCore";
import { MAX_ALLOWED_REF_PUNKTE } from "../../EivngCore"
import { FETCH_VERANSTALTUNG_REQUEST_SUCCESS, 
	DELETE_VERANSTALTUNG, 
	FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS } from "../veranstaltungen/actions";
import moment from "moment";
import { TEILNEHMER_MELDEN_PUSH_COMPLETED, REMOVE_ALL_SEIZED_TEILNEHMER } from "./teilnehmermeldenActionCreator";

const teilnahmeMeldungenOfVNRReducer = (stateMeldungenOfVNR = {}, action) => {
	switch(action.type){
		case FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS:
			const stateAggregiert = {...stateMeldungenOfVNR}
			action.punktemeldungen.map( (punkteMeldung) => {
				stateAggregiert[punkteMeldung.efn] = punkteMeldung
				return punkteMeldung
			})
			return stateAggregiert
		default:	
	}
	return stateMeldungenOfVNR
}
/** 
 * state sind meldungen als { vnr : { efn : efnMeldung }}
 */ 
export const teilnahmeMeldungenReducer = (state = {}, action) => {
	const vnr = action.vnr
	switch(action.type){
		case DELETE_VERANSTALTUNG:
			const data = {...state}
			delete data[vnr]
			return data;
		default: 
	}
	if(vnr === undefined) return state
	const stateMeldungenOfVNR = state[vnr]
	return {...state, [vnr]: teilnahmeMeldungenOfVNRReducer(stateMeldungenOfVNR, action)}
}

export const initialStateAddTeilnahme = {
	efn: "",
	punkte_basis_flag : true,
	punkte_lernerfolg_flag : false,
	punkte_referent : 0,
	teilnahmedatum : null
}

/**
 * wenn das eine laufende Veranstaltung, dann macht es Sinn das heutige Datum vorzuschlagen, sonst wird Anfang der Veranstaltung vorgeschlagen
 * @param {*} veranstaltung 
 * @returns 
 */
const getInitialTeilnahmedatum = (veranstaltung) => {
	const now = moment();
	const beginn = moment(veranstaltung.beginn);
	const ende = moment(veranstaltung.ende);
	if(now.isSameOrAfter(beginn) && now.isSameOrBefore(ende)){
		return now.endOf("day").valueOf();
	}
	const teilnahmedatum = moment(veranstaltung.beginn).endOf("day").valueOf();
	return teilnahmedatum;
}

export const addTeilnahmeReducer = (state = {}, action) => {
	switch(action.type){
		case FETCH_VERANSTALTUNG_REQUEST_SUCCESS:
			const teilnahmedatum = getInitialTeilnahmedatum(action.veranstaltung);
			return {...state, [action.veranstaltung.vnr]: {...initialStateAddTeilnahme, teilnahmedatum}}
		case DELETE_VERANSTALTUNG:
			const data = {...state}
			delete data[action.vnr]
			return data	
		default:

	}
	if(action.vnr === undefined) return state
	const teilnahmeOfVNR = state[action.vnr]
	if(teilnahmeOfVNR !== undefined) return {...state, [action.vnr]: addTeilnahmeOfVNRReducer(teilnahmeOfVNR, action)}
	return state
}
export const qrReaderReducer = (state = {}, action) => {
	switch(action.type){
		case ADD_TEILNAHME_QR_READER_TOGGLE_MULTISCAN_MODE:
			return {...state, multiscanMode: !state.multiscanMode}
		default: return state;
	}
}

export const addTeilnahmeOfVNRReducer = (state = initialStateAddTeilnahme, action) => {
	switch(action.type){
		case ADD_TEILNAHME_HINZUGUEGEN:
			return {...state, efn: "", 
				punkte_referent : 0
			}
		case PUNKTEMELDUNG_REMOVE_TEILNEHMER:
			if(!fobiCore.isEFN(state.efn)) return {...state, efn: action.efn}
			return state;
		case REMOVE_ALL_SEIZED_TEILNEHMER:
			// if(!fobiCore.isEFN(state.efn) && action.efns ) return {...state, efn: action.efns}
			if( action.efns ) return {...state, efn: action.efns}
			return state;
		case ADD_TEILNAHME_CHANGE_EFN:
			return {...state, efn: action.payload}
		case ADD_TEILNAHME_SANITIZE_EFNS:{
			const unsanitizedEFNs = state.efn;
			const sanitizesArrayOfEFNs = fobiCore.extractEFNsToArray(unsanitizedEFNs);
			const efn = sanitizesArrayOfEFNs?sanitizesArrayOfEFNs.join(' '):"";
			return {...state, efn};
		}	
		case ADD_TEILNAHME_REF_PUNKTE_INCREASE:
					return {...state, punkte_referent: state.punkte_referent < MAX_ALLOWED_REF_PUNKTE?++state.punkte_referent:MAX_ALLOWED_REF_PUNKTE}
		case ADD_TEILNAHME_REF_PUNKTE_DECREASE:
				return {...state, punkte_referent: state.punkte_referent > 0?--state.punkte_referent:0}
		case ADD_TEILNAHME_REF_PUNKTE_CHANGE:
				return {...state, punkte_referent: action.payload}
		case ADD_TEILNAHME_CHANGE_TEILNAHMEDATUM:
				return {...state, teilnahmedatum: action.teilnahmedatum}					
		case ADD_TEILNAHME_TOGGLE_PUNKTE_BASIS:
				return {...state, punkte_basis_flag: !state.punkte_basis_flag}
		case ADD_TEILNAHME_TOGGLE_PUNKTE_LERNERFOLG:
				return {...state, punkte_lernerfolg_flag: !state.punkte_lernerfolg_flag}		
		default: return state;
	}
	
}
/** 
 * state sind erfassteTeilnahmen, bzw. Teilnehmer als { vnr : { efn : efnMeldung }}
 */ 
export const erfassteTeilnahmenReducer = (state = {}, action) => {
	switch(action.type){
		case DELETE_VERANSTALTUNG:
			const teilnahmenData = {...state}
			delete teilnahmenData[action.vnr]
			return teilnahmenData;
		case REMOVE_ALL_SEIZED_TEILNEHMER:
			{
				return {...state, [action.vnr] : {}}
			}
		case TEILNEHMER_MELDEN_PUSH_COMPLETED:
		{
				const teilnahmen = {...state[action.vnr] }
				delete teilnahmen[action.efn]; 
				return {...state, [action.vnr] : teilnahmen}
		}
		case PUNKTEMELDUNG_REMOVE_TEILNEHMER:
				const teilnahmen = {...state[action.vnr] }
				delete teilnahmen[action.efn]; 
			return {...state, [action.vnr] : teilnahmen}
		
		case ADD_TEILNAHME_EFN_QRCODE_SCANNED:{
			const intendedEFN = action.payload;
			const teilnahmeState = {...initialStateAddTeilnahme };
			if(action.addTeilnahmeState){
				teilnahmeState.teilnahmedatum = action.addTeilnahmeState.teilnahmedatum;
				teilnahmeState.punkte_basis_flag = action.addTeilnahmeState.punkte_basis_flag;
				teilnahmeState.punkte_lernerfolg_flag = action.addTeilnahmeState.punkte_lernerfolg_flag;
				teilnahmeState.punkte_referent = action.addTeilnahmeState.punkte_referent;

			}
			
			const teilnahmen = { ...state[action.vnr] };
			if(!fobiCore.isEFN(intendedEFN)) return state;
			const efn = intendedEFN;
			teilnahmen[efn] = {...teilnahmeState, efn};
			return {...state, [action.vnr] : teilnahmen, lastScannedEFN: efn };
		}
		
		case ADD_TEILNAHME_HINZUGUEGEN:
			const efns = action.addTeilnahmeState.efn;
			// bei mehreren EFNs keine Refentenpunkte oder Lernerfolgt automatisch schreiben
			// console.log("before: %s", JSON.stringify(action.addTeilnahmeState));
			const teilnahmeState = fobiCore.containsMultipleEFNs(efns)?{...action.addTeilnahmeState, punkte_lernerfolg_flag:false, punkte_referent:0 }:action.addTeilnahmeState;
			// console.log("after: %s", JSON.stringify(teilnahmeState));

			if( fobiCore.isVNR(action.vnr) && fobiCore.validateMultipleEFNs(efns) ){
				//const teilnahmen = {...state[action.vnr], [action.addTeilnahmeState.efn] : action.addTeilnahmeState}
				const teilnahmen = { ...state[action.vnr] };
				fobiCore.extractEFNsToArray(efns).forEach(efn => {
					teilnahmen[efn] = {...teilnahmeState, efn};
				});
				return {...state, [action.vnr] : teilnahmen }
			}
			return state;	
		case PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_CHANGE:
			const pmrpcTeilnahme = {...state[action.vnr][action.efn], punkte_referent: action.refPunkte}
			const pmrpcTeilnahmen = {...state[action.vnr], [action.efn] : pmrpcTeilnahme}
			return  {...state, [action.vnr] : pmrpcTeilnahmen }
		case PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_INCREASE:
			let pmrpipunkteReferent = state[action.vnr][action.efn].punkte_referent;
			const pmrpiTeilnahme = {...state[action.vnr][action.efn], punkte_referent: pmrpipunkteReferent < MAX_ALLOWED_REF_PUNKTE?
				++pmrpipunkteReferent:pmrpipunkteReferent }
			const pmrpiTeilnahmen = {...state[action.vnr], [action.efn] : pmrpiTeilnahme}
			return  {...state, [action.vnr] : pmrpiTeilnahmen }
		case PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_DECREASE:
			let pmrpdpunkteReferent = state[action.vnr][action.efn].punkte_referent;
			const pmrpdTeilnahme = {...state[action.vnr][action.efn], punkte_referent: pmrpdpunkteReferent > 0?
				--pmrpdpunkteReferent:pmrpdpunkteReferent }
			const pmrpdTeilnahmen = {...state[action.vnr], [action.efn] : pmrpdTeilnahme}
			return  {...state, [action.vnr] : pmrpdTeilnahmen }	
		case PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_BASIS:
			const tbTeilnahme = {...state[action.vnr][action.efn], punkte_basis_flag: !state[action.vnr][action.efn].punkte_basis_flag}
			const teilnahmenTB = {...state[action.vnr], [action.efn] : tbTeilnahme}
			return  {...state, [action.vnr] : teilnahmenTB }
		case PUNKTEMELDUNG_TEILNEHMER_CHANGE_TEILNAHMEDATUM:
				const tbchdTeilnahme = {...state[action.vnr][action.efn], teilnahmedatum: action.teilnahmedatum}
				const teilnahmenTBCHD = {...state[action.vnr], [action.efn] : tbchdTeilnahme}
				return  {...state, [action.vnr] : teilnahmenTBCHD }
			
		case PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_LERNERFOLG:
			const tlTeilnahme = {...state[action.vnr][action.efn], punkte_lernerfolg_flag: !state[action.vnr][action.efn].punkte_lernerfolg_flag}
			const teilnahmenTL = {...state[action.vnr], [action.efn] : tlTeilnahme}
			return  {...state, [action.vnr] : teilnahmenTL }
		
		default: return state;
	}
}