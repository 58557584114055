import React from 'react'
import { Container, Row, Col, Button, Input } from 'reactstrap';
import { notConnectedFunction } from '../EIVCommons';
import EFNHolderAlert from './EFNHolderAlert';
import { MdQrCodeScanner } from 'react-icons/md';
import { scanEFNBarcode } from '../scanbarcode/cordovascan';
import { mobileAppModus } from '../EivngCore';

export const EFNSetupEingabe = (props) => {
	const { 
		efn = "", networkFetchCounter = 0, authToken = "", isValidEFN = false, networkActionsDisabled,
		changeAuthToken = notConnectedFunction, setupEFN = notConnectedFunction,
		changeEFN = notConnectedFunction, close = notConnectedFunction, showConfirmDialog } = props
		const setupButtonEnabled = networkFetchCounter === 0 && isValidEFN && authToken.length > 0;
	return (
		<Container className="mt-2 mb-2">
		<Row className="form-group">
			<Col lg className="mt-2 mb-2">
				<Row >
					<label className="col-sm-5 col-form-label text-sm-right" htmlFor="vnr">EFN</label>
					<div className="col-sm-7">
						<Input id="vnr" placeholder="Ihre EFN"
							className={"form-control"} required={true} valid={isValidEFN} type="text" autoFocus = {!mobileAppModus}
							onChange={(e) => changeEFN(e.target.value)} value={efn} />
					</div>
				</Row>
			</Col>
			<Col lg className="mt-2 mb-2">
				<Row >
					<label className="col-sm-5 col-form-label text-sm-right" >Auth.- Kennwort</label>
					<div className="col-sm-7">
						<Input id="efnAuthToken" placeholder="z.B. secret (Prototyp)" className="form-control" required={true} type="password"
							onChange={(e) => changeAuthToken(e.target.value)} value={authToken} />
					</div>
				</Row>
			</Col>
		</Row>
		<EFNHolderAlert />
		<div className="text-right">
			<div className="row">
				<div className="col-4 text-left">
				{ mobileAppModus &&
					<Button title="EFN Barcode einscannen" className="d-inline"  color="secondary" outline 
							onClick={ (e) => { scanEFNBarcode(changeEFN, showConfirmDialog) }}> 
							<MdQrCodeScanner fontSize="1em" color="orange" ></MdQrCodeScanner>
					</Button>
				}
				</div>
				<div className="col-8">
					<Button className="mb-4" disabled={!setupButtonEnabled || networkActionsDisabled} color="info" onClick={ (e) => setupEFN(efn, authToken) } >EFN einrichten</Button>
					<Button className="mb-4 ml-4"  outline color="secondary" onClick={ (e) => close() } >Abbrechen</Button>
				</div>
			</div>
		</div>
	
</Container>
	)
}
