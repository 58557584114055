import React from 'react'
import i18next from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux'
import { closePushNotificationDialog } from '../store/pushNotificationDialog/actions';

const PushNotificationDialog = (props) => {
	const { isOpen, onClose, onConfirm = () => console.log("onConfirm"), title, message } = props
	return(
		<>
		<Modal centered={true} isOpen={isOpen} toggle={ () => onClose() }>
		<ModalHeader toggle={ () => onClose()}>
			{title}
		</ModalHeader>
		<ModalBody className="backgroundColor">
			<div className="lead">{message}</div>
		</ModalBody>
		<ModalFooter  className="backgroundColor">
			{ onConfirm !== null && 
		  <button type="button" className="btn btn-warning" onClick = { (e) => onConfirm() }>{i18next.t('ok')}</button>
			}
		  <button type="button" className="btn btn-secondary" onClick = { (e) => onClose() }>{i18next.t('close')}</button>
		</ModalFooter>
	</Modal>
	</>
	)
}
const mapStateToProps = (state) => {
	return {
		isOpen: state.pushNotificationDialog.isOpen,
		title: state.pushNotificationDialog.title,
		message: state.pushNotificationDialog.message,
		onConfirm: state.pushNotificationDialog.onConfirm
	}
} 
const mapDispatchToProp = (dispatch) => ({
	onClose: () => dispatch(closePushNotificationDialog()),
})

export default connect(mapStateToProps, mapDispatchToProp)(PushNotificationDialog)