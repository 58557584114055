import React from 'react';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import moment from 'moment';
registerLocale('de', de)
//setDefaultLocale('de')


const TeilnahmedatumAsSpan = (props) => {
	const { minDate, maxDate, startDate, onChange, isClearable } = props
		class TeilnahmedatumSpan extends React.Component{
			constructor(props){
				super(props);
				this.value = props.value;
				this.onClick = props.onClick;
			}
				render = () => {
					const startDate = this.props.startDate;
					const momentStartDate = moment(startDate).startOf('day');
					const invalidTeilnahmedatum = momentStartDate.isAfter(moment().startOf('day'));
					const addOnClassName = invalidTeilnahmedatum?" text-danger":""
				return(			
					<span className={"clickableValue" + addOnClassName} onClick={this.onClick}>{this.value}</span>
				)
				}
		}
// const addOnClassName = startDate===minDate?"is-valid":""
	const momentStartDate = moment(startDate).startOf('day');
	const invalidTeilnahmedatumInZukunft = momentStartDate.isAfter(moment().startOf('day'));
	return(
		<span>
			<DatePicker
				dateFormat="dd.MM.yyyy"
				className={"form-control " /*+addOnClassName*/}
				selected={startDate}
				startDate={startDate}
				customInput={<TeilnahmedatumSpan startDate={startDate}/>}
				locale="de"
				isClearable={isClearable}
				onChange={onChange}
				placeholderText="dd.mm.yyyy"
				minDate={minDate}
				maxDate={maxDate}
      />
			{ invalidTeilnahmedatumInZukunft &&
				<div className="invalid-feedback d-block">
					Teilnamedatum liegt in der Zukunft.
				</div>
			} 
		</span>
	)
}
export default TeilnahmedatumAsSpan