import React from 'react';
import i18next from 'i18next';
import {connect} from 'react-redux'

import { Alert, Table } from 'reactstrap'
import ErfassteTeilnahme from './ErfassteTeilnahme';
import { ListOfErfasstenTeilnehmer } from './ListOfErfasstenTeilnehmer';
import { fobiCore } from '../../FobiCore';

function teilnahmenCompare( a, b ) {
  if ( a.addedTimestamp < b.addedTimestamp ){
    return 1;
  }
  if ( a.addedTimestamp > b.addedTimestamp ){
    return -1;
  }
  return 0;
}


const ErfassteTeilnehmer = (props) => { 
	const { vnr, showTeilnahmedatum, erfassteTeilnahmenOfVNR = {}, veranstaltung, highLightRefpunkte = false } = props
	const teilnahmen = Object.values(erfassteTeilnahmenOfVNR).sort(teilnahmenCompare)
	const keineTeilnahmen = teilnahmen.length === 0;
	const showLernerfolg = fobiCore.isShowLernerfolg(veranstaltung)
	const classNameWithRefPunkte = highLightRefpunkte?"font-weight-bold bg-warning":"";
	if(keineTeilnahmen) return (
		<KeineTeilnehmerErfasst />
	)
  return (
		<>
		<ListOfErfasstenTeilnehmer className="d-md-none  mb-4" vnr = {vnr} classNameAddOnWithRefPunkte = {classNameWithRefPunkte}
		showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} teilnahmen={teilnahmen} veranstaltung={veranstaltung}
		/>
		<Table responsive size="sm" className="mt-4 mb-4 d-none d-md-table">
		<HeaderTeilnahmen showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} />
		<tbody>
    {
			teilnahmen.map(teilnahme => (
				<ErfassteTeilnahme showTeilnahmedatum={showTeilnahmedatum} efn={teilnahme.efn} veranstaltung={veranstaltung} vnr={vnr} 
				className = {teilnahme.punkte_referent > 0?classNameWithRefPunkte:""}
				key={teilnahme.efn} />
      ))}
		</tbody>
  </Table>
	</>
	);
}

const mapStateToProps = (state, ownProps) => (
	{
		erfassteTeilnahmenOfVNR : state.punkteMeldung.erfassteTeilnahmen[ownProps.vnr],
		veranstaltung : state.veranstaltungen[ownProps.vnr]
	}
)
export default connect(mapStateToProps)(ErfassteTeilnehmer)

function KeineTeilnehmerErfasst(props) {
  return (
		<Alert color="" className="h6 text-center mt-4 mb-4 lead">
			{i18next.t('keine.teilnehmer.erfasst')}
		</Alert>
	);
}

function HeaderTeilnahmen(props) {
	const { showTeilnahmedatum, showLernerfolg } = props
  return (
		<thead>
			<tr>
				<th>EFN</th>
				{ showTeilnahmedatum &&
					<th>Teilnahmedatum</th>
				}
				<th>Teilnahme</th>
				{ showLernerfolg &&
				<th>
					<span className=" d-lg-none">Lernerfolg</span>
					<span className="d-none d-lg-inline" >Lernerfolg</span>
				</th>
				}
				<th>Ref/Mod/WL Pkte.</th>
				<th></th>			
				</tr>
			</thead>
	);
}