import { activateNotificationsShowAlert, showHeaderAlert } from "../alerts/actions"
import { createAnId } from "../../EIVCommons"
import { BACKEND_BASE_URL, BACKEND_KAMMERDIENER_PREFIX, eivngCore, getHeaderWithBearerAutorization } from "../../EivngCore"
import { messageOn401EFNHolder } from '../../components/HeaderAlert'

export const ACTIVATE_NOTIFICATIONS_STARTED = 'ACTIVATE_NOTIFICATIONS_STARTED'
export const ACTIVATE_NOTIFICATIONS_COMPLETED = 'ACTIVATE_NOTIFICATIONS_COMPLETED'
export const ACTIVATE_NOTIFICATIONS_PERMISSION_NOT_GRANTED = 'ACTIVATE_NOTIFICATIONS_PERMISSION_NOT_GRANTED'
export const PUSH_NOTIFICATION_SET_SUBSCRIPTION = 'PUSH_NOTIFICATION_SET_SUBSCRIPTION'
// export const PUSH_NOTIFICATION_UNSET_SUBSCRIPTION = 'PUSH_NOTIFICATION_UNSET_SUBSCRIPTION'
export const EFN_SUBSCRIPTION_RESQUEST_STARTED = 'EFN_SUBSCRIPTION_RESQUEST_STARTED'
export const EFN_SUBSCRIPTION_RESQUEST_OK = 'EFN_SUBSCRIPTION_RESQUEST_OK'
export const EFN_SUBSCRIPTION_RESQUEST_FAILED = 'EFN_SUBSCRIPTION_RESQUEST_FAILED'
export const UNSUBSCRIBE_EFN_STARTED = 'UNSUBSCRIBE_EFN_STARTED'
export const UNSUBSCRIBE_EFN_COMPLETED = 'UNSUBSCRIBE_EFN_COMPLETED'
export const UNSUBSCRIBE_EFN_FAILED = 'UNSUBSCRIBE_EFN'

export const activateNotificationsCompleted = () => {
	return { type: ACTIVATE_NOTIFICATIONS_COMPLETED }
}
export const efnUnsubcriptionStarted = () => {
	return { type: UNSUBSCRIBE_EFN_STARTED }
}
export const efnUnsubcriptionCompleted = () => {
	return { type: UNSUBSCRIBE_EFN_COMPLETED }
}
export const efnUnsubscriptionFailed = () => {
	return { type: UNSUBSCRIBE_EFN_FAILED }
}
export const efnSubcriptionRequestStarted = () => {
	return { type: EFN_SUBSCRIPTION_RESQUEST_STARTED }
}
export const efnSubcriptionRequestOK = (subscriptionId) => {
	return { type: EFN_SUBSCRIPTION_RESQUEST_OK, payload: subscriptionId }
}
export const efnSubcriptionRequestFailed = (error) => {
	return { type: EFN_SUBSCRIPTION_RESQUEST_FAILED, payload: error}
}
export const pushNotficationSetSubscription = (subscription) => {
	return { type: PUSH_NOTIFICATION_SET_SUBSCRIPTION, payload: subscription}
}
export const activateNotificationsStarted = () => {
	return { type: ACTIVATE_NOTIFICATIONS_STARTED }
}
export const activateNotificationsPermissionNotGranted = () => {
	return { type: ACTIVATE_NOTIFICATIONS_PERMISSION_NOT_GRANTED }
}

export const showNotification = (title, body) => {
  return async dispatch => {
      try{  
      const status = await Notification.requestPermission()
      console.debug(status)
      switch(status){
        case "denied":
				case "default":
						console.info("missing permission to show notification", title, body)
				break;
				case "granted":
					const registration = await navigator.serviceWorker.getRegistration()
					if(registration === undefined){
						console.info("show notification failed. Service worker not registered ...")
					}else{
						const options = {
							body,
							//icon: 'images/notification-flat.png',
							vibrate: [100, 50, 100],
							data: {
								dateOfArrival: Date.now(),
								primaryKey: createAnId()
							},
						
							actions: [
								// {action: 'explore', title: 'Go to the site',
								// 	icon: 'images/checkmark.png'},
								// {action: 'close', title: 'Close the notification',
								// 	//icon: 'images/xmark.png'
								// },
							]
						};
						registration.showNotification(title, options);
					}
				break;
        default:
      }
      
    }catch(err){
      console.error('showNotification Error :-S', err);
    }
	}
}

export const subscribeEFN = (efn, bearer, pmSubscription) => {
  return async dispatch => {
		dispatch(efnSubcriptionRequestStarted());
	
		 //  ("Authorization", "Basic " + btoa(username + ":" + password));
		 let headers = getHeaderWithBearerAutorization(bearer);
		 try{  
			 const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/teilnehmer/subscribe-efn/" + efn,
			 {
			 method: 'post',
			 credentials: 'include',
			 body: JSON.stringify(pmSubscription),
			 headers: headers			
			 })
			 const status = response.status
			 //console.debug(status)
			 switch(status){
				 case 200:
						 const jsonData = await response.json()
						 console.debug('Fetched Data:', jsonData);
						 
						 const efnSubscriptionId = jsonData.subscriptionId
						 // TODO clean dispatches ...
						 dispatch(efnSubcriptionRequestOK(efnSubscriptionId));
						 dispatch(activateNotificationsShowAlert(
							`Einrichtung erfolgreich. Abonnierte EFN ${efn}.`, "success"))
						 break;
				 default:
					dispatch(efnSubcriptionRequestFailed(status));
			 }
			 
		 }catch(err){
			 console.error('EFN holder Punktemeldungen Fetch Error :-S', err);
			 dispatch(efnSubcriptionRequestFailed(err));
		 }
	}
}
const vapidKeys = {
	// NEVER PUT YOUR PRIVATE KEY FOR PRODUCTION INTO GIT 
  publicKey: "BEYXWYO30_9AsqQ8rX5nNXTOVdH3IdxLoYz1UFn-ymtaaQbSZ7Z-hEQn9ZtulIWWCGS7ZtyZMqJWDgYalEJOIzg"
};
/*
The private key is here
-----BEGIN PGP MESSAGE-----

hQIMA1B/N9zJ/YswARAAn/Txb3M5IYiKBbS1vmMcVrI6MX0Y2ftB0avATssKkkLV
GaOgyo6wZwI3yK4DgJ9JiKMds1aRJlET7Bi746DMVJ9T+1YfSSSN60lY5qDQEsHu
63WV4g3eBMhCL+X1/TheFQMwzdCYXHPcPwXairD2gDOvDA2PsgMleW1NUKMHPa9+
6HOaKvh44RVeDEOVLhgm72Y8Yn4bkVpJbDDjjFPPagiUC+Ts+07iXEwGQimVKTvg
LyFRbdu9iOqVLQQX1hXX/Qxk/F0+MZzlRluXUOpvJnXgT4k70+xBo6kwwlobAsiW
LHuQc8zJ7rc8bO7F+5Rk2KvN4bEAZdGQoVwBK8lNh6m2jcaji7W/EsZ5AFGqLj+r
jiw0DtxIl77jCsz907r7eKtoGqwSXjn4kgyyEAhx8/VnMkFIQNMkICOQ1WM9gS4I
+5g/mahGTeY39cSv3QQW9YmMeBFgzV0v/nZzHwjYqbS/e4l6pFlVlEu7UZL3+IcN
m7SP0nfNNOK2fFca8qYKMt5Dh5xdJcRefrRN/MeI4ul3zyv4R9V6/fGeI8b30sLx
ikyi2zm3chCKxkckpggf+uoVf3W/eOUAvzb+K9WpidWZcJoJBxHKfi+/XrWl6KOs
fkIK2l/TOqvIVt55ZFwNVJk98VPiS0WLc9Pro6HKvy42es/g7dVTCmarfTtrsVGF
AQwD+dch1ucy9VcBB/4vHoRMOeH0HKb65D3GDpD7G2+bBgpye/VqMll6DhxGp+ia
5mYFoV7JnQvrYiVEE9qZ9XwUqVYxlFovm0PBjA4+xFIad/fSVSJ5axcITDOujafr
UuV5T0yPEiB25dD7tqoyYdNyCaa70fs2Bek/1Xzp97J9rb5CLdZxu+3jnrnywR8t
Srz4cx5r8gNDggQdxyBLSOj2t3LedLBQF7QkAWBs4KBceyw2fGWVIkFNkQ+/ZX7a
yupsW0bfzqt4yVOKoHbjPDXX6KG2g8Lhm2kvEEB4jsZcvA8ZeDtaKBHDqYzn3Bdj
AGTDCU5WYTIHoZ5GmoCNK1R0xoWHeX5xK4zcs+eNhQEMA1MGhWrqQZ15AQgAhhrr
MalfXHbfxgNPxOApk2Lhy7QVGityZ3H1QiJRegpF5ysVBywJHTzG93XSZUZx+FPM
PdYVHrtggN2jlnAswUbyyJRbPXpM6sToD9Xs8724JDm+dU6MF/Nv8pEcOtOEsQot
/ulx0MKc+5bxDoLQURYcrdDPWmW1+5aeYIY62PSOKWYVQrZOC4tMpv/3DzBAFBo/
rJHawsc2iscrE/qyntDn8in4ZPhI6HWfpMGRQYcg6CsMgPGk1M3TjtJjDr9xhlop
2cS2zWD5FkUQarhnFOKt9O3IdFks19bs8m3aj0LTvkeEPxeUAnsa3fx8KrmrjYfI
2y9iGFe5l+tWpQ0kV4UBDAMguhQWD9iehQEH/34dva6uQE+i1p9m08fBWpkOUddN
WrnnLWbht5Yo/p7OXAx+BvEkxes1ra+bWP0fgovWUxRjZ0+qlv4LF0LD/TTnyBY2
Mokn1QnzJavJfcR2oTySOyfp82pzHCSJZTgH8Em2XdEuFV7dqNkbQxEVdwI+Sohz
t7E5yv8RfmbxGrsu9zLSJ+YskzyY8lYHU9xXfehPeLazWGNNgHArgsGaopY6URKN
jeuzvgq5gzsp1PztCfbbqqfc0nkiA9YSzaPzOTdoBRWF+GFAzGy1vUHghq0zV2tO
+lXSMF5gnKgmpI+478rrlQSiMCmZrulUiC5ZJQhqoksN6MrkTY6uo5ywNVaFAgwD
AAAAAAAAAAABD/0bWoPMQUmHU517u8fpF81NrHbVbW7gle2duHn3UGYjPfTnhNa7
A5EmeZZVTlxAx6aiMu9LOmw23t7Oc/ml0beYrtwhBDV+VBEfGHkmVFFL2hLQ3aQk
uaqddJqkrvs337PTmRF0ToYK0V8ByPrtq0k2BJ4h1itbqI4DoctNwN6SYP2116XZ
K4vqTuCvtOl4jZhsmu/UjXKuPjdhDiv3zXvXCf7gUaaebgrYjrOLrPJUh/llshl+
Up36s4UjpK5BmRtkfJB2oj56wjhspqfRJlTitAyMDV0GnR/WXbybjA5QOPcr6pHe
RmOyhACmxYwTe+oJ/bm8SZ4gJdfkMLHvNplbkzIeD763Xd7n4NUpIM4uAUve4fJL
AaMmPjBmglZ4yhy1Bs+0ONXjLJi6qduZL3ToItrAl2i8cw7HNh+oJEDHZ0OE+GQ2
pM4mlAxWNuhfp0c1dSohiFMOHT6nOqZwCsH1i6aavYOroCZipTXLLT+vWUZBvDcp
Y/0xKWTOvMzLHANFP3AQmu8Fj/j3xd4lMyGMrvIK9PNVFv6MGx4KaoBEU6AST3VA
uyxUXElgC81PGKV3nxyIignxuZfAQIqzwPt/hLpAdfK/Rf/5iD/EFo5NmQySAkMb
Dt0iOfTt12JNYzr/wwQoafsmBNg5ZB9VoqwGitZSP9GQM68DZSjqzbU8RIUBDAMA
AAAAAAAAAAEH/1YRRfzZbFPQ1jYMUwDgMruHnmQDJWvANOdIeYnIxNTIB/6qGzB7
TaMfbjP3jt79/D2dEfeTyS3+CW8+uiAuzOKf3QN0SNkrLCLx9R1V1SjN+4soLc2w
FKk95HN9RH4T/CT8zYSyKHzJIZXyXGtFEp15aOnjtw0nXehteJI5yPXkBrMSZFLe
UVyJXbpjqQRNqbQynAF7rCqNvcroFUAffm6N9u5oFkCNvZxKdODSOvp5CdTknmxf
5/lDRhR/g8EwiIk732GpP/gDk0kdUzx/wAvxPSHFzVA0yFPHl8FvGDH5+qvfM8NX
Sd+RTOKJYFvYJRb2b/vKhEtq0N74BMEGW8rSwBsB8CqBrb94ho9nX/anwCeTVUwH
4FE5WSRc/u38hVQL/mKtZjmsqy5Z6R6eSscCxRa3fwQQCG+5PvTA1X4oMty6ML8O
VLNSLj2kuAwIlK1eMiWqMd+y6z1qct313XYyEVjvuge2AUZ6lnjFiwqb12XkEqpG
ZTgeq4xMgECycFY7V08BVTBMTBi5s3ULa01mVCxjqoVzNSvgLBZwKEvkfcFEVHPn
L2o4e9GV4Jc1KJKwGezLlhr8Up495oHOSXAVzDR3hkFTGJ6E18fF19QLaLfhjXcY
FQHtNQbVZwk=
=CanL
-----END PGP MESSAGE-----

*/

export const pmSubscribe = (efn, bearer) => {
  return async dispatch => {
  
    dispatch(activateNotificationsStarted());
    console.debug('activate Notifications');
    try{  
			const status = await Notification.requestPermission()
			console.debug('status notifications', status);
      switch(status){
        case "denied":
				case "default":
						dispatch(activateNotificationsPermissionNotGranted());
				break;
				case "granted":
					if(navigator.serviceWorker === undefined || navigator.serviceWorker === null){
						dispatch(activateNotificationsShowAlert("Failed. Service worker does not exists", "warning"));			
						break
					}
					const registration = await navigator.serviceWorker.getRegistration()
					if(registration === undefined || registration === null){
						dispatch(activateNotificationsShowAlert("Failed. Service worker not registered ..."));
						break			
					}
					if(registration.pushManager === undefined || registration.pushManager === null){
						dispatch(activateNotificationsShowAlert("missing pushmanager ...", "warning"));
						break			
					}else{
						let pmSubscription = await registration.pushManager.getSubscription()
						
						console.log(pmSubscription)
						if(pmSubscription === null){
								pmSubscription = await registration.pushManager.subscribe({
								userVisibleOnly: true,
								applicationServerKey: vapidKeys.publicKey
							})
						
							dispatch(pushNotficationSetSubscription(JSON.stringify(pmSubscription)))
							console.debug(`Subscription activated`)
							// dispatch(activateNotificationsShowAlert(
							// 	`Web push subscription activated. Token: ${JSON.stringify(pmSubscription)}`, "success"))
						}
						dispatch(subscribeEFN(efn, bearer, pmSubscription))
						break
					}
				
        default:
      }
    }catch(err){
			console.error('pmSubscribe error', err);
      dispatch(activateNotificationsShowAlert(err));
    }finally{
      dispatch(activateNotificationsCompleted());
		}
  }
}
export const fcmSubscribeEFN = (efn, bearer, registrationId) => {
	
	return async dispatch => {
		dispatch(efnSubcriptionRequestStarted());
	
		 let headers = getHeaderWithBearerAutorization(bearer);
		 try{  
			 const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/teilnehmer/fcm-subscribe-efn/" + efn,
			 {
			 method: 'post',
			 credentials: 'include',
			 body: JSON.stringify({ registrationId, platformId: window.cordova.platformId }),
			 headers: headers			
			 })
			 const status = response.status
			 //console.debug(status)
			 switch(status){
				 case 200:
						 const jsonData = await response.json()
						 console.debug('Fetched Data:', jsonData);
						 
						 const efnSubscriptionId = jsonData.subscriptionId
						 // TODO clean dispatches ...
						 dispatch(efnSubcriptionRequestOK(efnSubscriptionId));
						 dispatch(activateNotificationsShowAlert(
							`Einrichtung erfolgreich. Abonnierte EFN ${efn}.`, "success"))
						 break;
				 default:
					dispatch(efnSubcriptionRequestFailed(status));
			 }
			 
		 }catch(err){
			 console.error('EFN holder Punktemeldungen Fetch Error :-S', err);
			 dispatch(efnSubcriptionRequestFailed(err));
		 }
	}
}
const wait = (ms) => new Promise(resolve => {
	setTimeout(resolve, ms)
})
export const pmCordovaSubscribe = (efn, authToken) => {
  return async dispatch => {
  
    dispatch(activateNotificationsStarted());
		console.debug('activate cordova Notifications');
		const cordovaPushNotification = eivngCore.cordovaPushNotification
    try{
			const firstTimeRegistration = eivngCore.cordovaPushNotification.registrationData?false:true
			const registrationData = await cordovaPushNotification.initAndRegisterPushNotification()
			// dem Anwender Zeit lassen Push Nachrichten zu erlauben, sonst muss er dann noch mal den Button aktivieren drucken
			if(firstTimeRegistration && window.cordova.platformId === 'ios') await wait(3 * 1000)
			const permissionData = await cordovaPushNotification.hasPermission()
			console.log('asked for permission', permissionData);
			if( permissionData.isEnabled){
				dispatch(fcmSubscribeEFN(efn, authToken, registrationData.registrationId))
			}else{
				dispatch(activateNotificationsPermissionNotGranted());
			}

    }catch(err){
			console.error('pmSubscribe error', err);
      dispatch(activateNotificationsShowAlert(err));
    }finally{
      dispatch(activateNotificationsCompleted());
		}
  }
}
export const unsubscribeEFN = (efn, bearer, subscriptionId, showAlerts = true) => {
	return async dispatch => {
		if(!subscriptionId) {
			if (showAlerts) dispatch(activateNotificationsShowAlert(`Kein EFN Abo zum abbestellen gefunden`, "warning"))
		return true
		}
		dispatch(efnUnsubcriptionStarted());
	 	let headers = getHeaderWithBearerAutorization(bearer);
	 	try{  
		 	const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX +"/fobi/teilnehmer/unsubscribe-efn",
			{
				method: 'post',
				credentials: 'include',
				body: JSON.stringify({id : subscriptionId}),
				headers: headers			
			})
			 const status = response.status
			 
			 switch(status){
				case 422:
					dispatch(efnUnsubcriptionCompleted())
					break
				case 200:
					const jsonData = await response.json()
					console.debug('unsubscribed row:', jsonData)
					dispatch(efnUnsubcriptionCompleted())
					if (showAlerts) dispatch(activateNotificationsShowAlert(`Die Benachrichtigungen für Ihre EFN wurden deaktiviert`, "warning"))
					break;
				case 401:
					if (showAlerts) dispatch(showHeaderAlert(messageOn401EFNHolder, "danger"))
					dispatch(efnUnsubscriptionFailed(status))
					break
				 default:
					if (showAlerts) dispatch(activateNotificationsShowAlert(`Die Abmeldung fehlgeschlagen. Versuchen Sie es später erneut.`, "danger"))
					dispatch(efnUnsubscriptionFailed(status));
			 }
			 
		 }catch(err){
			 console.error('EFN unsubscribe failed. Error:', err);
			 if (showAlerts) dispatch(activateNotificationsShowAlert(`Die Abmeldung fehlgeschlagen. Versuchen Sie es später erneut.`, "danger"))
			 dispatch(efnUnsubscriptionFailed(err));
		 }
	}
}
