import React from 'react';
import i18next from "i18next"
import EIVHeader from '../EIVHeader';
import { Button } from 'reactstrap'
import { CSS_CLASSNAME_PANEL } from '../EIVCommons'
import EFNSetupPage from './EFNSetupPage';
import EFNHolderPunktemeldungen from './EFNHolderPunktemeldungenConnector'
// import  BeispielEFNs from './BeispielEFNs';


export function EFNHolderPage(props) {
	const { efn = "unbekannt", authToken, subscriptionId, efnSetupCompleted = false, efnAbmelden } = props
		
	const pageTitle = efnSetupCompleted?`EFN ${efn}`:i18next.t("efn.einrichten")
	return (
		<div className="container">
			<EIVHeader></EIVHeader>
			<h3>{pageTitle}</h3>
			<div className={CSS_CLASSNAME_PANEL}>
					<EFNSetupPage />
					<EFNHolderPunktemeldungen />
			</div>
			{ !efnSetupCompleted &&
				<>
					{/* <BeispielEFNs/> */}
				</>
				}
			{ efnSetupCompleted &&	
				<div className="text-right">
				<Button className="mb-4" color="warning" onClick={ () => efnAbmelden(efn, authToken, subscriptionId) } >EFN Abmelden</Button>
				</div>
			}
		</div>
	);
}
