
import { iso3166CountryCodes } from './common/iso.js'; 
// const VNR_PREFIX = '276'
const EFN_PREFIX = '8'
	/**
	 * Checks whether a string of digits is a valid credit card number according
	 * to the Luhn algorithm.
	 *
	 * 1. Starting with the second to last digit and moving left, double the
	 * value of all the alternating digits. For any digits that thus become
	 * 10 or more, add their digits together. For example, 1111 becomes 2121,
	 * while 8763 becomes 7733 (from (1+6)7(1+2)3).
	 *
	 * 2. Add all these digits together. For example, 1111 becomes 2121, then
	 * 2+1+2+1 is 6; while 8763 becomes 7733, then 7+7+3+3 is 20.
	 *
	 * 3. If the total ends in 0 (put another way, if the total modulus 10 is
	 * 0), then the number is valid according to the Luhn formula, else it is
	 * not valid. So, 1111 is not valid (as shown above, it comes out to 6),
	 * while 8763 is valid (as shown above, it comes out to 20).
	 *
	 * @param number the credit card number to validate.
	 * @return true if the number is valid, false otherwise.
	 */
	export 	const isValidNumberAccordingLuhn = (numberAsString) => {
		if( null === numberAsString.match(/^\d+$/) ) return false;
		let sum = 0;
		let alternate = false;
		for (let i = numberAsString.length - 1; i >= 0; i--) {
			let n = Number.parseInt(numberAsString.substring(i, i + 1));
			if (alternate) {
				n *= 2;
				if (n > 9) {
					n = (n % 10) + 1;
				}
			}
			sum += n;
			alternate = !alternate;
		}
		return (sum % 10 === 0);
	}
	
	export 	const isVNR = (text) => {
		if(text === null) return false;
		const countryCode = text.substring(0, 3);
		if (!iso3166CountryCodes.includes(countryCode)) return false;
		return text.length === 19 && isValidNumberAccordingLuhn(text);
	}
	
export const isEFN = (text) => {
	// console.log("checking efn %s", text)
		if(text == null) return false;
		return text.length === 15 && text.startsWith(EFN_PREFIX) && isValidNumberAccordingLuhn(text);
	}

export const isShowLernerfolg = (veranstaltung) => {
	return veranstaltung.punkte_lernerfolg > 0
}

// const regexForExtractEFNs = /[\s,;"']+/;
const regexForExtractEFNs = /[\D]+/;

export const validateMultipleEFNs = (inputOfOneOrMultipleEFNs) => {
	const efnArray = inputOfOneOrMultipleEFNs.split(regexForExtractEFNs).filter(e => e);
	return efnArray.every(isEFN);
}

export const findEFNInText = (text) => {
	const foundEFN = text.split(regexForExtractEFNs).find(e => isEFN(e));
	return foundEFN;
}

export const extractEFNsToArray = (inputOfOneOrMultipleEFNs) => {
	const efnArray = inputOfOneOrMultipleEFNs.split(regexForExtractEFNs).filter(e => isEFN(e));
	return efnArray;
}

export const extractInvalidEFNsToArray = (inputOfOneOrMultipleEFNs) => {
	const efnArray = inputOfOneOrMultipleEFNs.split(regexForExtractEFNs).filter(e => e && !isEFN(e));
	return efnArray;
}

export const containsMultipleEFNs = (inputOfOneOrMultipleEFNs) => {
	const efnArray = inputOfOneOrMultipleEFNs.split(regexForExtractEFNs).filter(e => e);
	// console.log(efnArray);
	return efnArray.length > 1;
}

export const fobiCore = { isEFN, isVNR, isShowLernerfolg, validateMultipleEFNs, containsMultipleEFNs, extractEFNsToArray, extractInvalidEFNsToArray, findEFNInText }