import { fobiCore } from '../FobiCore'

const regBarCodeEfnEinrichtung = /#;#FC#;#80276[0-9]{10}#;#.*#;#/g

// example value: #;#FC#;#802760600079148#;#secret#;#
// create test barcode: https://portal.laekh.de:8443/barcode?text=%23%3b%23%46%43%23%3b%23%38%30%32%37%36%30%36%30%30%30%37%39%31%34%38%23%3b%23%73%65%63%72%65%74%23%3b%23

export const recognizeEfnEinrichtungsCode = (text) => {
	const m = text.match( regBarCodeEfnEinrichtung )
	if(m === null) return null
	const token = m[0]
	const efn = token.slice(8, 23);
	if(!fobiCore.isEFN(efn)) return null
	const authToken = token.slice(26, token.indexOf('#;#', 26));
	const result = { efn, authToken }
	return result
}