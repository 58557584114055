import React from 'react';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import moment from 'moment';
registerLocale('de', de)
//setDefaultLocale('de')

const AbweichendesTeilnahmedatum = (props) => {
	const { minDate, maxDate, startDate, onChange, isClearable } = props
	const momentStartDate = moment(startDate).startOf('day');
	const invalidTeilnahmedatum = momentStartDate.isAfter(moment().startOf('day'));
	const addOnClassName = invalidTeilnahmedatum?" is-invalid":""

	return(
		<span>
			<DatePicker
				dateFormat="dd.MM.yyyy"
				className={"form-control " + addOnClassName}
				selected={startDate}
				startDate={startDate}
				locale="de"
				isClearable={isClearable}
				onChange={onChange}
				placeholderText="dd.mm.yyyy"
				minDate={minDate}
				maxDate={maxDate}
				/>
				{ invalidTeilnahmedatum &&
					<div className="invalid-feedback d-block">
						Teilnamedatum liegt in der Zukunft.
					</div>
				}
		</span>
	)
}
export default AbweichendesTeilnahmedatum