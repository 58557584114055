import moment from "moment";
const kategorienMitVergabeDerRefPunkte = ["A", "H"];

const getDauerInMinuten = (veranstaltung) => {
    const beginn = moment(veranstaltung.beginn);
    const ende = moment(veranstaltung.ende);
    const duration = moment.duration(ende.diff(beginn));
    return duration.asMinutes();
}

const estimateMaxAllowedRefPunkte = (veranstaltung) => {
    if (kategorienMitVergabeDerRefPunkte.indexOf(veranstaltung.kategorie) >= 0){
        //hier gilt so etwa dauer in minuten / 45 + 1 punkt für WL
        const minuten = getDauerInMinuten(veranstaltung);
        if(minuten > 480) return 12;
        return 1 + Math.ceil(minuten / 45); 
    }
    return 1;
}

export const validateRefPunkte = (veranstaltung, erfassteTeilnahmen, teilnahmeMeldungen) => {
    const mergedTeilnahmen = {...teilnahmeMeldungen, ...erfassteTeilnahmen };
    const summeRefpunkte = Object.values(mergedTeilnahmen).reduce((accumulator, teilnahme) => {
       return accumulator + teilnahme.punkte_referent
    }, 0);
    // console.debug(`Summ of ref. Punkte: ${summeRefpunkte}`);
    const maxAllowedPunkte = estimateMaxAllowedRefPunkte(veranstaltung);
    console.debug(`max allowed Punkte: ${maxAllowedPunkte}`);
    return maxAllowedPunkte >= summeRefpunkte;
}
export const validateGemeldeteRefPunkte = (veranstaltung, teilnahmeMeldungen) => {
    const summeRefpunkte = Object.values(teilnahmeMeldungen).reduce((accumulator, teilnahme) => {
        return accumulator + teilnahme.punkte_referent
    }, 0);
    // console.debug(`Summ of gemeldeten ref. Punkte: ${summeRefpunkte}`);
    const maxAllowedPunkte = estimateMaxAllowedRefPunkte(veranstaltung);
    return maxAllowedPunkte >= summeRefpunkte;
}
