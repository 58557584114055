import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import { /* useSelector, */ useDispatch } from 'react-redux'
import { addVeranstaltungPanelSetVNRCredentials, fetchVeranstaltungWithJWT } from '../store/veranstaltungen/actions';
import useDebouncedToast from './DebouncedToast';
import MultiFormatReader from "./MultiFormatReader";
import { getVeranstaltungsCredentials } from '../store/veranstaltungen/fobiAppBarcodeContentRecogniser';
import { fobiCore } from '../FobiCore';
import { getDeviceId } from './MultiFormatReaderUtils';
import { Howl } from 'howler';
import { getVeranstaltungsJWT } from '../store/veranstaltungen/handler';

const soundScanOk = new Howl({
  // src: ['./sounds/message-124468.mp3'] // Pfad zum Sound-File
  src: ['./sounds/camera-13695.mp3'] // Pfad zum Sound-File
});
const soundScanNOk = new Howl({
  src: ['./sounds/stop-13692.mp3'] // Pfad zum Sound-File
});


const VnrCredentialsScannerDialog = ({ isOpen, toggle }) => {
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [facingMode, setFacingMode] = useState('environment');
    const [showCamera, setShowCamera] = useState(true);
    const debouncedToast = useDebouncedToast(2000);

    const dispatch = useDispatch();
    // const efn = useSelector(state => state.punkteMeldung.addTeilnahme[vnr].efn);
    const applyVeranstaltungsCredentials = (vnr, pwd) => dispatch(addVeranstaltungPanelSetVNRCredentials(vnr, pwd));
    const applyVeranstaltungWithJWT = (jwt) => dispatch(fetchVeranstaltungWithJWT(jwt));
 
    useEffect(() => {
      const fetchDevices = async () => {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter((device) => device.kind === 'videoinput');
        setDevices(videoDevices);
        if (videoDevices.length > 0) {
          try{
            const deviceId = await getDeviceId(mediaDevices, facingMode);
            setSelectedDevice(deviceId);
          }catch(e){
            setSelectedDevice(videoDevices[0].deviceId);
          }
        }
      };

      fetchDevices();
    }, [facingMode]);

 

  const onScan = (data) => {
    if(data && data.text){
      const text = data.text;
      const credentials = getVeranstaltungsCredentials(text);
      if(credentials) {
        const {vnr, pwd} = credentials
        applyVeranstaltungsCredentials(vnr, pwd);
        debouncedToast(`gelesen VNR: ${vnr}`, "success");
        soundScanOk.play();
        toggle();
        return;
      }
      const jwt = getVeranstaltungsJWT(text);
      if(jwt){
        applyVeranstaltungWithJWT(jwt);
        debouncedToast(`Einrichtungstoken gelesen. Veranstaltung wird geladen`, "success");
        soundScanOk.play();
        toggle();
        return;
      }
      // console.log('handle scan');
      const efn = text.split(/\s+/).find(e => fobiCore.isEFN(e));
      if(efn) {
        debouncedToast(`eine EFN anstatt Zugangangsdaten gefunden. Ist es richtiger QR-code ?`, "info", () => soundScanNOk.play());
      }else debouncedToast(`Keine Zugangsdaten erkannt. Ist es richtiger QR-code ?`, "warning", () => soundScanNOk.play());
      
    }
  };
  
  const refreshCameraView = () => {
    setShowCamera(false); setTimeout( () => setShowCamera(true), 30);
  }
 
  const toggleFacingMode = () => {
    setFacingMode(facingMode === 'user' ? 'environment' : 'user');
    refreshCameraView();
  };
  
  

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Veranstaltungs- Zugangsdaten einlesen (QRCode) 
      </ModalHeader>
      <ModalBody>
       { showCamera &&
      <MultiFormatReader onScan={ onScan } constraints={{ deviceId: selectedDevice, facingMode }}/>
          // <QrReader
          //   delay={300}
          //   onError={handleError}
          //   onResult={handleScan} 
          //   facingMode={facingMode}
            
          //   constraints={{ deviceId: selectedDevice, facingMode }}
          //   legacyMode={!selectedDevice}
          //   style={{ width: '100%' }}
          //   className="my-4"
        
          // />
        }
        {devices.length > 1 && (
          <FormGroup>
            <Label for="deviceSelect">Kamera auswählen</Label>
            <Input
              type="select"
              name="deviceSelect"
              defaultValue={selectedDevice}
              id="deviceSelect"
              onChange={(e) => {
                setSelectedDevice(e.target.value); refreshCameraView();
                }}
            >
              {devices.map((device, index) => (
                <option key={index} value={device.deviceId}>{device.label || `Kamera ${index + 1}`}</option>
              ))}
            </Input>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
   
        <Button color="primary" block onClick={toggleFacingMode}>Front-/Rückkamera wechseln</Button>
        {/* <Button color="primary" onClick={toggleTorch}>Taschenlampe {torch ? 'ausschalten' : 'einschalten'}</Button> */}
        <Button color="secondary" onClick={toggle}>Schließen</Button>
      </ModalFooter>
    </Modal>
  );
};

export default VnrCredentialsScannerDialog;
