import { SET_SCREEN, appScreens, SET_SCREEN_VERANSTALTUNG, 
	DISPLAY_DIALOG_QRCODE_LINK_TO_APP,
	SET_SAVE_STATE_TO_LOCAL_STORAGE } from "./actions";
import { mobileAppModus } from "../../EivngCore";

const initialState = { 
	currentScreen : appScreens.startPage,
	selectedVNR : "",
	saveStateToLocalStorage : mobileAppModus,
	displayDialogQrcodeLinkToApp: false
}
export const appNavigationReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SCREEN:
			return  {...state, currentScreen : action.payload}
		case DISPLAY_DIALOG_QRCODE_LINK_TO_APP:
			return  {...state, displayDialogQrcodeLinkToApp : action.payload}
		case SET_SCREEN_VERANSTALTUNG:
			return  {...state, 
				currentScreen : appScreens.veranstaltungPage,
				selectedVNR : action.payload
			}
		case SET_SAVE_STATE_TO_LOCAL_STORAGE:
			return  {...state, saveStateToLocalStorage : action.payload}

		default:
			return state;
	}
}
