import moment from 'moment';

export const getMinDate = (veranstaltung) => moment(veranstaltung.beginn).startOf("day").valueOf()
export const isVeranstaltungsBeginnInZukunft = (veranstaltung) => getMinDate(veranstaltung) > moment().startOf("day").valueOf()
export const getMaxDate = (veranstaltung) => {
	const maxDate = moment(veranstaltung.ende).startOf("day").valueOf();
	const today = moment().startOf("day").valueOf();
	return today > maxDate?maxDate:today
}
const KATEGORIEN_MIT_ABWEICHENDEN_TEILNAHMEDATUM = ["D", "I", "H"];
export const istBeginnUndEndeDatumGleich = (veranstaltung) => getMinDate(veranstaltung) === getMaxDate(veranstaltung)
export const isShowTeilnahmedatum = (veranstaltung) => 
	!istBeginnUndEndeDatumGleich(veranstaltung) && KATEGORIEN_MIT_ABWEICHENDEN_TEILNAHMEDATUM.indexOf(veranstaltung.kategorie) >= 0
export const isPreventTeilnahmedatumMeldung = (veranstaltung) => 
	istBeginnUndEndeDatumGleich(veranstaltung) || KATEGORIEN_MIT_ABWEICHENDEN_TEILNAHMEDATUM.indexOf(veranstaltung.kategorie) < 0
