import React from 'react'
import {mapStateToProps, mapDispatchToProp } from './ErfassteTeilnahme'
import {connect} from 'react-redux'
import { getMinDate, getMaxDate } from './teilnahmedatum';
import TeilnahmedatumAsSpan from '../TeilnahmedatumAsSpan';
import { FlagComponent } from '../FlagComponent';
import { Button } from 'reactstrap';
import ErfassteTeilnahmeReferentenpunkte from './ErfassteTeilnahmeReferentenpunkte';

const ErfassteTeilnahmeListItem = (props) => {
	const { key, istKorrekturMeldung, efn, vnr, showTeilnahmedatum, showLernerfolg, 
		className,
		veranstaltung, changeTeilnahmedatum, teilnahme,
		togglePunkteBasis, togglePunkteLernerfolg, removeTeilnahme } = props
	const korrekturMeldungTitle=istKorrekturMeldung?"Korrekturmeldung":null
	const classNameAddOn = istKorrekturMeldung?"text-primary":""
	const minDate = getMinDate(veranstaltung);
	const maxDate = getMaxDate(veranstaltung);
	const teilnahmedatum = teilnahme.teilnahmedatum;

	return(
		<li className={`${className} list-group-item`} key={key} >
			<div className={"row " + classNameAddOn} title={korrekturMeldungTitle}>
				{ showTeilnahmedatum && 
				<div className="col ">	
					<TeilnahmedatumAsSpan minDate={minDate} 
						useSpan={true} maxDate={maxDate} onChange={changeTeilnahmedatum} startDate={teilnahmedatum}></TeilnahmedatumAsSpan>
				</div>
				}
				<div className="col align-self-end text-right">{efn}</div>
			</div>
		
			<div className="row text-secondary">
				<div className="col-8  h5">
					<span className="font-weight-normal badge badge-light">Teilnahme 	<FlagComponent fontSize="1.6em" 
						flag={teilnahme.punkte_basis_flag} onClick={togglePunkteBasis} />
					</span>&nbsp; 
					{showLernerfolg &&
						<>
						<span className="font-weight-normal badge badge-light">Lernerfolg <FlagComponent fontSize="1.6em" 
							flag={teilnahme.punkte_lernerfolg_flag} onClick={togglePunkteLernerfolg} /></span>
						&nbsp;
						</>
					}
					<span className="font-weight-normal badge badge-light d-inline-block">Ref/Mod/WL Pkte.:&nbsp;
						<ErfassteTeilnahmeReferentenpunkte vnr={vnr} efn={efn}></ErfassteTeilnahmeReferentenpunkte>
					</span>&nbsp;
					
				</div>
				<div className="col align-self-end text-right">
				<Button  onClick={() => removeTeilnahme()} size="sm" outline color="danger">Entfernen</Button>
				</div>
			</div>
		</li>
	)
}

const ConnectedErfassteTeilnahmeListItem = connect(mapStateToProps, mapDispatchToProp)(ErfassteTeilnahmeListItem);
export default ConnectedErfassteTeilnahmeListItem
