// import { BACKEND_BASE_URL, BACKEND_KAMMERDIENER_PREFIX } from "../../EivngCore";
import jwt_decode from "jwt-decode";
import { isVNR } from "../../FobiCore";

/**
 * prüft ob im Text entweder eine JSON mit dem jwt token oder der Token selbst als Text übergeben wird
 * @param {*} text - jwt token oder der json mit dem jwt token
 * @returns jwt Token, falls als Veranstaltungstoken verwendet werden kann, oder null falls nicht erkannt
 */
export const getVeranstaltungsJWT = ( text ) => {
	if (!text) return null;

	let tokenCandidate = text.trim();
	try{
		const json = JSON.parse(text);
		tokenCandidate = json.jwt;
		if(!tokenCandidate) return null;
	}catch( err ){
		// parse Error, ok, kein json
	}

	try{
		const claims = jwt_decode(tokenCandidate);
		const intendedVNR = claims.sub;
		const aud = claims.aud;
		const iss = claims.iss;
		if( isVNR(intendedVNR) && aud === "vnr" && "butler" === iss ){
			// can be an jwt token
			return tokenCandidate;
		}
	}catch(error){
		// can't parse jwt, assume that is not jwt we awaiting
	}
	return null;
}