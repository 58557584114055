import React from 'react'
import { connect } from 'react-redux'
import ErfassteTeilnahmeListItem from './ErfassteTeilnahmeListItem';

export const ListOfErfasstenTeilnehmer = (props) => {
	const {showLernerfolg, showTeilnahmedatum, teilnahmen, 
		classNameAddOnWithRefPunkte = "",
		veranstaltung, vnr} = props

	return(
		<div className = {props.className}>
			<ul className="list-group">
			{teilnahmen.map(teilnahme => (
				<ErfassteTeilnahmeLI showTeilnahmedatum={showTeilnahmedatum} showLernerfolg={showLernerfolg} 
				className = {teilnahme.punkte_referent > 0?classNameAddOnWithRefPunkte:""}
				efn={teilnahme.efn} veranstaltung={veranstaltung} vnr={vnr} 
				key={teilnahme.efn} />
      ))}
			</ul>
		</div>
	)
}

const mapStateToProps = (state, ownProps) => {
	return{
		//istInEingabe: ownProps.punktemeldung.efn === state.punkteMeldung.addTeilnahme[ownProps.vnr].efn
	}
}
const ErfassteTeilnahmeLI = connect(mapStateToProps)(ErfassteTeilnahmeListItem)