
import { 
	FETCH_VERANSTALTUNG_REQUEST_SUCCESS, 
	ADD_VERANSTALTUNG_PANEL_SET_VNR, 
	ADD_VERANSTALTUNG_PANEL_SET_VNRPWD, 
	ADD_VERANSTALTUNG_PANEL_SET_VNR_CREDENTIALS,
	DELETE_VERANSTALTUNG,
	ADD_VERANSTALTUNG_PANEL_SET_COLLAPSED} from './actions'
	
import { recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd } from './fobiAppBarcodeContentRecogniser';

const initialStateAddVeranstaltungsPanel = {
		vnr: "",
		vnrpwd: "",
		showAddVeranstaltungArea: false
	};


const stummModusDurationinMS = 3200

const addVeranstaltungController = { stummModus : false, timeoutHandle: null }

const disengageStummModus = () => {
	addVeranstaltungController.stummModus = false
	addVeranstaltungController.timeoutHandle = null
}
const engageStummModus = () => {
	addVeranstaltungController.stummModus = true
	if(addVeranstaltungController.timeoutHandle) clearTimeout(addVeranstaltungController.timeoutHandle)
	addVeranstaltungController.timeoutHandle = setTimeout( () => disengageStummModus(), stummModusDurationinMS)
}

export const addVeranstaltungPanelReducer = (state = initialStateAddVeranstaltungsPanel, action) => {
 switch(action.type) {
	 	case ADD_VERANSTALTUNG_PANEL_SET_VNRPWD:{
			if(addVeranstaltungController.stummModus) {
				engageStummModus()
				return state;
			}
			const data = recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd(action.payload) 
			if(data === null) return {...state, vnrpwd: action.payload};
			else{
				engageStummModus()
				return {...state, vnrpwd: data.vnrpwd, vnr: data.vnr};
			} 
		 }
		case ADD_VERANSTALTUNG_PANEL_SET_COLLAPSED :{
			return {...state, showAddVeranstaltungArea: action.payload}
		}
		case ADD_VERANSTALTUNG_PANEL_SET_VNR:{
			if(addVeranstaltungController.stummModus){ 
				engageStummModus()
				return state;
			}
			const data = recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd(action.payload)
			if(data === null) return {...state, vnr: action.payload };
			else{
				engageStummModus()
				 return {...state, vnrpwd: data.vnrpwd, vnr: data.vnr};
			}
		}
		case ADD_VERANSTALTUNG_PANEL_SET_VNR_CREDENTIALS:{
			const {vnr, pwd} = action.payload;
			return {...state, vnrpwd: pwd, vnr};
		}

		case FETCH_VERANSTALTUNG_REQUEST_SUCCESS:
			return {...state, vnr: "", vnrpwd: "", showAddVeranstaltungArea: false}							
	 default:
		  return state;
 }
}

export const veranstaltungenReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_VERANSTALTUNG:
			const veranstaltungen = {...state}
			delete veranstaltungen[action.vnr]
			return veranstaltungen
		case FETCH_VERANSTALTUNG_REQUEST_SUCCESS:
			return {...state,  [action.veranstaltung.vnr] : action.veranstaltung }
		default:
			return state;
	}
}

