export function isEFNinTeilnahmeMeldungen(efn, vnr, state){
	const punkteMeldungen = state.punkteMeldung.teilnahmeMeldungen[vnr]
	if(punkteMeldungen === undefined) return false;
	return findEFN(punkteMeldungen, efn) >= 0
}

function findEFN(jsonRows, efn){
	let foundIndex = -1;
	Object.values(jsonRows).some((jsonRow, index) => { 
		const matches = efn === jsonRow.efn
		if (matches) foundIndex = index;
		return matches; 
	});
	return foundIndex;
}

export function isEFNinErfasstenTeilnahmen(efn, vnr, state){
	if(state.punkteMeldung.erfassteTeilnahmen[vnr] === undefined) return false;
	return state.punkteMeldung.erfassteTeilnahmen[vnr][efn] !== undefined
}