import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deleteVeranstaltung, fetchVeranstaltungOnly } from '../store/veranstaltungen/actions';
import { setScreenVeranstaltung } from '../store/appNavigation/actions';
import { showConfirmDialog, closeConfirmDialog } from '../store/confirmDialog/actions';

function VeranstaltungsItem(props) {
	moment().locale(i18next.language);
	const { data, removeVeranstaltung, /*countErfasste, gemeldeteTeilnehmer, */ goToVeranstaltung } = props;

	const endeZeit = moment(data.beginn).format('DD.MM.YYYY') !== moment(data.ende).format('DD.MM.YYYY')?
	moment(data.ende).format('DD.MM.YYYY HH:mm'):moment(data.ende).format('HH:mm')

	return (
		<div className="clickableValue container-fluid"  onClick={ (e) => {goToVeranstaltung(data.vnr, data.bearer)}}>
			<div>{data.vnr}</div>
			<div className="h5 mb-1">{data.thema}</div>
			<div>{data.unterthema}</div>
			<div className="row text-muted ">
				<div className="col-12 col-sm-8 col-md-10 col-lg-9">
					Kat. ({data.kategorie})&nbsp;
					{moment(data.beginn).format('DD.MM.YYYY HH:mm')} - {endeZeit}
				</div>
				<div className="col-12 col-sm-4 col-md-2 col-lg-3 text-right ">
				<Button  onClick={(e) =>  {e.preventDefault(); e.stopPropagation(); removeVeranstaltung()}} size="sm" outline color="danger">Entfernen</Button>
			</div>
		</div>			
					
		{/* <span>{countErfasste}</span>
		<span>{gemeldeteTeilnehmer}</span> */}
	</div>);
}

export function countDistinctTeilnehmer(teilnahmeMeldungen){
	if(teilnahmeMeldungen===undefined) return 0
	const punkteMeldungen = Object.values(teilnahmeMeldungen)
	const distinctEFNs = {}
	punkteMeldungen.map((item) => { distinctEFNs[item.efn] = 1; return item })
	return Object.keys(distinctEFNs).length

}

const mapStateToProps = (state, ownProps) => {
	const teilnahmeMeldungen = state.punkteMeldung.teilnahmeMeldungen[ownProps.data.vnr]
	return {
		gemeldeteTeilnehmer: countDistinctTeilnehmer(teilnahmeMeldungen)
	}

}
const mapDispatchToProp = (dispatch, ownProps) => ({
	removeVeranstaltung: () => {
		if( ownProps.countErfasste > 0){
			dispatch(showConfirmDialog(
				"Veranstaltung entfernen", 
				`Es gibt (${ownProps.countErfasste}) erfasste und noch nicht gemeldete Teilnehmer. Diese Daten gehen dabei verloren. Trotzdem entfernen ?`,
				(e) => { 
					dispatch(deleteVeranstaltung(ownProps.data.vnr));
					dispatch(closeConfirmDialog());
				} ))
		}else{
			dispatch(deleteVeranstaltung(ownProps.data.vnr))
		}
	},
	goToVeranstaltung : (vnr, bearer) => {
		dispatch(fetchVeranstaltungOnly(bearer))
		dispatch(setScreenVeranstaltung(vnr))
	}
})

export default connect(mapStateToProps, mapDispatchToProp)(VeranstaltungsItem)