import React from 'react'
import { Alert } from 'reactstrap'
import { connect } from 'react-redux'
import PunktemeldungListItem from './PunktemeldungListItem'
import { EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT } from '../EivngCore'
import { keinPunktekontoHinweis } from '../EIVCommons'

const Hinweis = (props) => {
	return (
		<Alert color="" className="h5 text-center lead">
		{keinPunktekontoHinweis}
		</Alert>
	)
}

const ListOfPunktemeldungen = (props) => {
	const {punktemeldungen} = props
	if(punktemeldungen.length === 0) return null
  return (
		<>
		<Hinweis/>
		{props.children}
		{punktemeldungen.length === EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT &&
			<div className="">{ `Maximal werden ${EFN_HOLDER_PUNKTEMELDUNGEN_ROWS_LIMIT} letzten Punktemeldungen angezeigt` }</div>
		}
			<div className = {props.className}>
			<ul className="list-group">
				{punktemeldungen.map((punktemeldung, index) => <PunktemeldungListItem key={index} liKey={index}
				 punktemeldung={punktemeldung}/>)}
		
			</ul>
		</div>
		</>
	);
}
const mapStateToProps = (state, ownProps) => (
	{
		punktemeldungen : state.efnHolder.punktemeldungen
	}
)
export default connect(mapStateToProps)(ListOfPunktemeldungen);