
import { EFN_HOLDER_SET_EFN, EFN_HOLDER_SET_AUTHTOKEN, EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS,
	EFN_HOLDER_SETUP_EFN_SUCCESS,
	EFN_HOLDER_EFN_ABMELDEN, EFN_HOLDER_SET_VISIBLE_SCAN_MY_EFN_DIALOG
	} from './actions'
import { 
	EFN_SUBSCRIPTION_RESQUEST_STARTED, EFN_SUBSCRIPTION_RESQUEST_FAILED, EFN_SUBSCRIPTION_RESQUEST_OK,
	UNSUBSCRIBE_EFN_COMPLETED, UNSUBSCRIBE_EFN_STARTED, UNSUBSCRIBE_EFN_FAILED,
	ACTIVATE_NOTIFICATIONS_STARTED,	ACTIVATE_NOTIFICATIONS_COMPLETED
	}	from '../notifications/actions'
import { fobiCore } from '../../FobiCore';
import { recognizeEfnEinrichtungsCode } from '../../efnholder/efnEinrichtungsCodeRecognizer';

const stummModusDurationinMS = 3200

const efnEinrichtungController = { stummModus : false, timeoutHandle: null }

const disengageStummModus = () => {
	efnEinrichtungController.stummModus = false
	efnEinrichtungController.timeoutHandle = null
}
const engageStummModus = () => {
	efnEinrichtungController.stummModus = true
	if(efnEinrichtungController.timeoutHandle) clearTimeout(efnEinrichtungController.timeoutHandle)
	efnEinrichtungController.timeoutHandle = setTimeout( () => disengageStummModus(), stummModusDurationinMS)
}

const initialStateEFNHolder = {
		efn: "",
		efnDatamatrixBase64String: null,
		authToken: "",
		isValidEFN: false,
		subscribingPending: false,
		unsubscribingPending: false,
		showScanMyEFNDialog: false,
		efnSetupCompleted: false,
		punktemeldungen: []
	};

const efnHolderReducer = (state = initialStateEFNHolder, action) => {
 switch(action.type) {
	case EFN_SUBSCRIPTION_RESQUEST_STARTED:
	case ACTIVATE_NOTIFICATIONS_STARTED:	
		return {...state, subscribingPending : true} 
	case EFN_SUBSCRIPTION_RESQUEST_OK:
	case ACTIVATE_NOTIFICATIONS_COMPLETED:
		return {...state, subscribingPending : false} 
	case EFN_SUBSCRIPTION_RESQUEST_FAILED:
	case UNSUBSCRIBE_EFN_STARTED:
		return {...state, unsubscribingPending : true} 
	case UNSUBSCRIBE_EFN_COMPLETED:
	case UNSUBSCRIBE_EFN_FAILED:
		return {...state, unsubscribingPending : false} 
	case EFN_HOLDER_SET_VISIBLE_SCAN_MY_EFN_DIALOG:
		return {...state, showScanMyEFNDialog: action.payload}
	case EFN_HOLDER_EFN_ABMELDEN:
		return {...initialStateEFNHolder};
	case EFN_HOLDER_SET_EFN:{
		if(efnEinrichtungController.stummModus) {
			engageStummModus()
			return state;
		}
		const data = recognizeEfnEinrichtungsCode(action.payload) 
		if(data === null) return {...state, efn : action.payload, isValidEFN : fobiCore.isEFN(action.payload)};
		else{
			engageStummModus()
			return {...state, efn: data.efn, authToken: data.authToken, isValidEFN : true}
		}
	}
	case EFN_HOLDER_SET_AUTHTOKEN:{
		if(efnEinrichtungController.stummModus) {
			engageStummModus()
			return state;
		}
		const data = recognizeEfnEinrichtungsCode(action.payload) 
		if(data === null) return { ...state, authToken: action.payload };
		else{
			engageStummModus()
			return {...state, efn: data.efn, authToken: data.authToken, isValidEFN : true}
		}
	}
	case EFN_HOLDER_FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS:
		return {...state, punktemeldungen: action.payload}	
	case EFN_HOLDER_SETUP_EFN_SUCCESS:
		return {...state, efnSetupCompleted: true, bearer: action.payload.bearer, authToken: null, efnDatamatrixBase64String: action.payload.efnDatamatrixBase64String}	
	default:
		return state;
 }
}
export default efnHolderReducer
