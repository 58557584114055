import React from 'react';
import { MdClose } from 'react-icons/md' 
import { IoIosArrowDropup, IoIosArrowDropdown} from 'react-icons/io'
import { Badge } from 'reactstrap'


const ReferentenPunkteComponent = (props) => {
	const notBoundedFunction = () => console.log("function not bounded");
	const { punkte = 0, decreasePunkte = notBoundedFunction, increasePunkte = notBoundedFunction, setPunkte = notBoundedFunction} = props;
	const referentPunkteColor = punkte === 0?"light":punkte===1?"info":punkte < 3 ? "warning":"danger"
	const resetReferentnPunkteColor = punkte === 0?"grey":"red"
	return(
				<div className="d-inline-flex align-items-center">
					<span title="-1 Punkt" onMouseDown ={(e) => e.preventDefault()}>
						<IoIosArrowDropdown className="clickableValue" fontSize="1.4em" color="grey" onClick = { () => {if (punkte > 0) decreasePunkte()}} ></IoIosArrowDropdown>
					</span>	
					<strong><Badge style={{fontSize: "100%"}} color={referentPunkteColor} >{punkte===0?"-":punkte}</Badge></strong>
					<span title="+1 Punkt" onMouseDown ={(e) => e.preventDefault()}>
						<IoIosArrowDropup className="clickableValue" fontSize="1.4em" color="grey" onClick = { () => increasePunkte()}></IoIosArrowDropup>
					</span>
					<span title="keine Punkte" onMouseDown ={(e) => e.preventDefault()}>
					<MdClose className="clickableValue" fontSize="1.4em" color={resetReferentnPunkteColor} onClick = { () => 
						{if (punkte > 0) setPunkte(0)}} ></MdClose>
					</span>
				</div>
		
	)
}
export default ReferentenPunkteComponent