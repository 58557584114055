import React from 'react'
import { connect } from 'react-redux'
import { Alert } from 'reactstrap'

const isSafariOnIOS = () => {
	const userAgent = window.navigator.userAgent;
	const isAppleDevice = /iPhone|iPad|Macintosh/.test(userAgent);
	const isSafari = !(/Chrome|CriOS|OPR|Edg|Firefox|FxiOS/.test(userAgent)) && (/Safari/.test(userAgent) || /AppleWebKit/.test(userAgent));
	
	return isAppleDevice && isSafari;
};
const showPushSupportIOSHinweis = isSafariOnIOS();

const NotificationsNotSupportedHinweis = props => {
	const { notificationsSupported } = props
	if(notificationsSupported) return null
	return(<>
		<Alert className={props.className} color="warning">
			Dieser Browser unterstüzt keine Push Nachrichten.
		</Alert>
		{ showPushSupportIOSHinweis &&
		<Alert color="info">
			Ab iOS/iPadOS 16.4 sind Push-Benachrichtigungen verfügbar, wenn die Seite zum Home-Bildschirm hinzugefügt wird.
		</Alert>
		}
	</>
	)
}

const mapStateToProps = (state, ownProps) => (
	{
		notificationsSupported : state.notifications.supported || state.notifications.useCordovaPushNotification
	}
)
export default connect(mapStateToProps)(NotificationsNotSupportedHinweis)