import React, { useRef, useState, useEffect, useCallback } from "react";
import { BrowserMultiFormatReader, BarcodeFormat, NotFoundException } from "@zxing/library";
import { styles } from "./MultiFormatReaderUtils";
import { ViewFinder } from "./ViewFinder";

const MultiFormatReader = ({ onScan, constraints }) => {
    const videoRef = useRef(null);
    const cameraActiveRef = useRef(false);
    
    // eslint-disable-next-line 
    const memoizedOnScan = useCallback(onScan, []);
    const [facingMode, setFacingMode] = useState(constraints.facingMode || "environment");
  
    useEffect(() => {
      setFacingMode(constraints.facingMode);
    }, [constraints.facingMode]);

  const codeReader = useRef(
    new BrowserMultiFormatReader(null, 500, null, null, {
      possibleFormats: [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX],
    })
  ).current;

  useEffect(() => {
    const startDecoding = () => {
      setTimeout(() => {
        if (!videoRef.current) {
          return;
        }
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const updatedConstraints = {
          ...constraints,
          video: {
            ...constraints.video,
            facingMode: isIOS ? { exact: facingMode } : facingMode,
          },
        };
        cameraActiveRef.current = true;
        codeReader.reset();
        codeReader
          .decodeFromVideoDevice(updatedConstraints.deviceId, videoRef.current, (result, err) => {
            if (result) {
              memoizedOnScan(result);
            } else if (err && !(err instanceof NotFoundException)) {
              console.error(err);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 1024); // Verzögerung von 1000 ms
    };

    if (videoRef.current && !cameraActiveRef.current) {
      startDecoding();
    }

    return () => {
      console.log("codeReader stoppen");
      cameraActiveRef.current = false;
      codeReader.reset();
    };
  }, [videoRef, codeReader, memoizedOnScan, constraints.deviceId, facingMode, constraints]);

  return (
    <div
      style={{
        ...styles.container
      }}
    >
    <ViewFinder />  
    <video
      // className="my-4"
      ref={videoRef}
      style={{
        ...styles.video,
        
        // width: "100%",
        transform: constraints.facingMode === "user" ? "scaleX(-1)" : undefined,
      }}
      />
      </div>
  );
};

export default MultiFormatReader;
