import React from 'react'
import { FlagComponentReadOnly } from './FlagComponentReadOnly';
import moment from 'moment';

const PunktemeldungListItem = (props) => {
	const {punktemeldung, key, showTeilnahmedatum, 
		className,
		showLernerfolg, istInEingabe } = props
	const teilnahmedatum = punktemeldung.teilnahmedatum === null?null:moment(punktemeldung.teilnahmedatum).format('DD.MM.YYYY')
	let classNameAddOn = istInEingabe?"text-primary":""
	return(
		<li className={`${className} list-group-item`} key={key}>
			<div className={"row " + classNameAddOn}>
				<div className="col">{punktemeldung.efn}</div>
				{ showTeilnahmedatum && 
				<div className="col align-self-end text-right">{teilnahmedatum}</div>
				}
			</div>
			<div className="row text-secondary">
				<div className="col">
					<span className="badge badge-light">Teilnahme <FlagComponentReadOnly flag={punktemeldung.punkte_basis_flag} /></span>&nbsp; 
					{showLernerfolg &&
						<>
						<span className="badge badge-light">Lernerfolg <FlagComponentReadOnly flag={punktemeldung.punkte_lernerfolg_flag} /></span>
						&nbsp;
						</>
					}
					<span className="badge badge-light">Ref/Mod/WL Pkte.: {punktemeldung.punkte_referent}</span>&nbsp;
					
				</div>
				<div className="col align-self-end text-right">
					<span className="badge" >{moment(punktemeldung.last_modified).format('DD.MM.YYYY HH:mm:ss')}</span>
				</div>
			</div>	
		</li>
	)
}

export default PunktemeldungListItem