import React from 'react';
import { connect } from 'react-redux'
import { addTeilnahmeTogglePunkteBasis } from '../../../store/punkteMeldung/actions';
import { FlagComponent } from '../../FlagComponent';

const mapStateToPropsPunkteBasis = (state, ownProps) => ({ 
	flag : state.punkteMeldung.addTeilnahme[ownProps.vnr].punkte_basis_flag
})
const mapDispatchToPropPunkteBasis = (dispatch, ownProps) => ({
	onClick : () => dispatch(addTeilnahmeTogglePunkteBasis(ownProps.vnr))
})
const FlagBasisPunkteComponent = connect(mapStateToPropsPunkteBasis, mapDispatchToPropPunkteBasis)(FlagComponent)

export const BasisPunkte = (props) => {
	return(
		<div className="input-group inline-flex">
			<FlagBasisPunkteComponent vnr={props.vnr}/>&nbsp;
			<span className="col-form-label" title="Basispunkte bzw. Teilnahmepunkte für vollständige Teilnahme">Teilnahme ({props.punkte} Pkt.) </span>
		</div>
	)
}