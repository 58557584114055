

// Actions
export const ADD_TEILNAHME_CHANGE_EFN = 'ADD_TEILNAHME_CHANGE_EFN'
export const ADD_TEILNAHME_SANITIZE_EFNS = 'ADD_TEILNAHME_SANITIZE_EFNS'
export const ADD_TEILNAHME_CHANGE_TEILNAHMEDATUM = 'ADD_TEILNAHME_CHANGE_TEILNAHMEDATUM'
export const ADD_TEILNAHME_CHANGE_PUNKTE_REFERENT = 'ADD_TEILNAHME_CHANGE_PUNKTE_REFERENT'
export const ADD_TEILNAHME_TOGGLE_PUNKTE_BASIS = 'ADD_TEILNAHME_TOGGLE_PUNKTE_BASIS'
export const ADD_TEILNAHME_TOGGLE_PUNKTE_LERNERFOLG = 'ADD_TEILNAHME_TOGGLE_PUNKTE_LERNERFOLG'
export const ADD_TEILNAHME_REF_PUNKTE_INCREASE = 'ADD_TEILNAHME_REF_PUNKTE_INCREASE'
export const ADD_TEILNAHME_REF_PUNKTE_DECREASE = 'ADD_TEILNAHME_REF_PUNKTE_DECREASE'
export const ADD_TEILNAHME_REF_PUNKTE_CHANGE = 'ADD_TEILNAHME_REF_PUNKTE_CHANGEE'
export const ADD_TEILNAHME_HINZUGUEGEN = 'ADD_TEILNAHME_HINZUGUEGEN'
export const ADD_TEILNAHME_EFN_QRCODE_SCANNED = 'ADD_TEILNAHME_EFN_QRCODE_SCANNED'
export const ADD_TEILNAHME_QR_READER_TOGGLE_MULTISCAN_MODE = 'ADD_TEILNAHME_QRCODE_TOGGLE_MULTISCAN_MODE'


export const PUNKTEMELDUNG_REMOVE_TEILNEHMER = 'PUNKTEMELDUNG_REMOVE_TEILNEHMER'
export const PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_BASIS = 'PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_BASIS'
export const PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_LERNERFOLG = 'PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_LERNERFOLG'
export const PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_INCREASE = 'PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_INCREASE'
export const PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_DECREASE = 'PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_DECREASE'
export const PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_CHANGE = 'PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_CHANGE'
export const PUNKTEMELDUNG_TEILNEHMER_CHANGE_TEILNAHMEDATUM = 'PUNKTEMELDUNG_TEILNEHMER_CHANGE_TEILNAHMEDATUM'

// Action creator
export const addTeilnahmeSetEFN = (vnr, efn) => {
	return { type: ADD_TEILNAHME_CHANGE_EFN, payload : efn, vnr }
}
export const addTeilnahmeSanitizeEFNs = (vnr) => {
	return { type: ADD_TEILNAHME_SANITIZE_EFNS, vnr }
}
export const addTeilnahmeQRReaderToggleMultiscanMode = () => {
	return { type: ADD_TEILNAHME_QR_READER_TOGGLE_MULTISCAN_MODE }
}
export const addTeilnahmeQrCodeEFNScanned = (vnr, qrScanResult, addTeilnahmeState) => {
	return { type: ADD_TEILNAHME_EFN_QRCODE_SCANNED, payload : qrScanResult, vnr, addTeilnahmeState }
}
export const addTeilnahmeTogglePunkteBasis = (vnr) => {
	return { type: ADD_TEILNAHME_TOGGLE_PUNKTE_BASIS, vnr }
}
export const addTeilnahmeTogglePunkteLernerfolg = (vnr) => {
	return { type: ADD_TEILNAHME_TOGGLE_PUNKTE_LERNERFOLG, vnr }
}
export const addTeilnahmeRefPunkteInc = (vnr) => {
	return { type: ADD_TEILNAHME_REF_PUNKTE_INCREASE, vnr }
}
export const addTeilnahmeRefPunkteDec = (vnr) => {
	return { type: ADD_TEILNAHME_REF_PUNKTE_DECREASE, vnr }
}
export const addTeilnahmeRefPunkteSet = (vnr, refPunkte) => {
	return { type: ADD_TEILNAHME_REF_PUNKTE_CHANGE, payload: refPunkte, vnr }
}
export const teilnehmerRefPunkteSet = (vnr, efn, refPunkte) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_CHANGE, vnr, efn, refPunkte }
}
export const teilnehmerRefPunkteInc = (vnr, efn) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_INCREASE, vnr, efn }
}
export const teilnehmerRefPunkteDec = (vnr, efn) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_REF_PUNKTE_DECREASE, vnr, efn }
}
export const addTeilnehmer = (vnr, addTeilnahmeState) => {
	addTeilnahmeState.addedTimestamp = Date.now()
	return { type: ADD_TEILNAHME_HINZUGUEGEN, vnr, addTeilnahmeState }
}
export const changeTeilnahmedatum = (vnr, teilnahmedatum) => {
	return { type: ADD_TEILNAHME_CHANGE_TEILNAHMEDATUM, vnr, teilnahmedatum }
}
export const teilnehmerChangeTeilnahmedatum = (vnr, efn, teilnahmedatum) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_CHANGE_TEILNAHMEDATUM, vnr, efn, teilnahmedatum }
}
export const removeTeilnehmer = (vnr, efn) => {
	return { type: PUNKTEMELDUNG_REMOVE_TEILNEHMER, vnr, efn }
}
export const toggleTeilnehmerPunkteBasis = (vnr, efn) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_BASIS, vnr, efn }
}
export const toggleTeilnehmerPunkteLernerfolg = (vnr, efn) => {
	return { type: PUNKTEMELDUNG_TEILNEHMER_TOGGLE_PUNKTE_LERNERFOLG, vnr, efn }
}
