import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import {connect} from 'react-redux'
import { removeTeilnehmer, toggleTeilnehmerPunkteBasis, toggleTeilnehmerPunkteLernerfolg, teilnehmerChangeTeilnahmedatum } from '../../store/punkteMeldung/actions';
import { FlagComponent } from '../FlagComponent';
import ErfassteTeilnahmeReferentenpunkte from './ErfassteTeilnahmeReferentenpunkte';
import { getMinDate, getMaxDate } from './teilnahmedatum';
import TeilnahmedatumAsSpan from '../TeilnahmedatumAsSpan';
import { isEFNinTeilnahmeMeldungen } from '../../store/punkteMeldung/commonUtils';

function ErfassteTeilnahme(props) {
	const { teilnahme, istKorrekturMeldung, showTeilnahmedatum, changeTeilnahmedatum, 
		className,
		removeTeilnahme, togglePunkteBasis, togglePunkteLernerfolg, efn, vnr, veranstaltung } = props;
	const teilnahmedatum = teilnahme.teilnahmedatum;
	const minDate = getMinDate(veranstaltung);
	const maxDate = getMaxDate(veranstaltung);
	const korrekturMeldungTitle=istKorrekturMeldung?"Korrekturmeldung":null
	const trClassNameAddOn = istKorrekturMeldung?"text-primary":null
	return (<tr className={`${className} ${trClassNameAddOn}`} title={korrekturMeldungTitle}>
		<td >{teilnahme.efn}</td>
		{ showTeilnahmedatum &&
		<td>
			<TeilnahmedatumAsSpan minDate={minDate} 
			 useSpan={true} maxDate={maxDate} onChange={changeTeilnahmedatum} startDate={teilnahmedatum}></TeilnahmedatumAsSpan>
		</td> 
		}
		<td><FlagComponent fontSize="1.6em" flag={teilnahme.punkte_basis_flag} onClick={togglePunkteBasis}></FlagComponent></td>
		{
			veranstaltung.punkte_lernerfolg > 0 &&
		<td><FlagComponent fontSize="1.6em" flag={teilnahme.punkte_lernerfolg_flag} onClick={togglePunkteLernerfolg}></FlagComponent></td>
		}
		<td><ErfassteTeilnahmeReferentenpunkte vnr={vnr} efn={efn}></ErfassteTeilnahmeReferentenpunkte></td>
		<td><Button  onClick={() => removeTeilnahme()} size="sm" outline color="danger">Entfernen</Button></td>
	</tr>);
}

export const mapStateToProps = (state, ownProps) => {
	return  {	
		teilnahme : state.punkteMeldung.erfassteTeilnahmen[ownProps.vnr][ownProps.efn],
		istKorrekturMeldung : isEFNinTeilnahmeMeldungen(ownProps.efn, ownProps.vnr, state)
	 }
}

export const mapDispatchToProp = (dispatch, ownProps) => ({
	removeTeilnahme: () => dispatch(removeTeilnehmer(ownProps.vnr, ownProps.efn)),
	togglePunkteBasis: () => dispatch(toggleTeilnehmerPunkteBasis(ownProps.vnr, ownProps.efn)),
	togglePunkteLernerfolg: () => dispatch(toggleTeilnehmerPunkteLernerfolg(ownProps.vnr, ownProps.efn)),
	changeTeilnahmedatum:  (teilnahmedatum) => {
		if(!teilnahmedatum) teilnahmedatum = moment(ownProps.veranstaltung.beginn).toDate()
		const minDate = getMinDate(ownProps.veranstaltung);
		console.log(teilnahmedatum)
		return dispatch(teilnehmerChangeTeilnahmedatum(ownProps.vnr, ownProps.efn, 
			teilnahmedatum===null?minDate:moment(teilnahmedatum.getTime()).endOf("day").valueOf()))
	}
})

const connectedErfassteTeilnahme = connect(mapStateToProps, mapDispatchToProp)(ErfassteTeilnahme);
export default connectedErfassteTeilnahme