import Either from 'data.either'
const APPLICATION_JSON = 'application/json'

export const fetchJson = async (url, method = 'GET', data = null) => {
	try{
		let headers = new Headers()
		headers.append('Accept', APPLICATION_JSON)
		if( data ) headers.append('Content-Type', APPLICATION_JSON)
		
		const body = data?JSON.stringify(data):undefined
		const response = await fetch(url, {
			method,
			mode: 'cors',
			credentials: 'include',
			headers,
			body
		}) 
		const json = await response.json()
		return Either.Right({ response, json })
	} catch (error) {
		console.error('Error: ', error.message)
		return Either.Left({ error })
	}
}

const getAuthHeaderLoginPwd = (login, pwd) => {
  const headers = new Headers()
  headers.append('Accept', 'application/json; charset=UTF-8')
  headers.append('Authorization', "Basic "+ btoa(`${login}:${pwd}`))
  headers.append( 'Content-type', 'application/json; charset=UTF-8')
  return headers
}

export const fetchJsonWithBasicAuth = async (login = 'login', pwd = 'secret', url, method = 'GET', data = null) => {
	
	try{
		const headers = getAuthHeaderLoginPwd(login, pwd)
		const body = data?JSON.stringify(data):undefined
	
    const response = await fetch(url,
		{
			method,
			credentials: 'include',
			mode: 'cors',
			body,
			headers
		});
		if(!response.ok) return Either.Left({response})
		const json = await response.json()
		return Either.Right({ response, json })
	}catch (error) {
		console.error('Error: ', error)
		return Either.Left({error})
	}
}

export const getStatusOnLeft = (response) => {
	if(response.isLeft && response.value && response.value.response && response.value.response.status){
		const status = response.value.response.status
		return status;
	}
	return null;
}

export const fetchJsonWithJWT = async (token = '', url, method = 'GET', data = null) => {
	try{
		let headers = new Headers()
		headers.append('Accept', APPLICATION_JSON)
		headers.append('Authorization', `Bearer ${token}`)
		if( data ) headers.append('Content-Type', APPLICATION_JSON)
		const body = data?JSON.stringify(data):undefined
		const response = await fetch(url, {
			method,
			mode: 'cors',
			credentials: 'include',
			headers,
			body
		})
		if(!response.ok) return Either.Left({response})
		const json = await response.json()
		return Either.Right({ response, json })
	}catch (error) {
		console.error('Error: ', error)
		return Either.Left({error})
	}
}


