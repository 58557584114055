export const CONFIRM_DIALOG_SHOW = 'CONFIRM_DIALOG_SHOW'
export const CONFIRM_DIALOG_CLOSE = 'CONFIRM_DIALOG_CLOSE'

// Action creator
export const showConfirmDialog = (title, message, onConfirm) => {
	return { type: CONFIRM_DIALOG_SHOW, title, message, onConfirm}
}
export const closeConfirmDialog = () => {
	return { type: CONFIRM_DIALOG_CLOSE}
}
