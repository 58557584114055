import React from 'react'
import { Alert } from 'reactstrap'
import i18next from 'i18next';

const KeineVeranstaltungen = (props) => {
  return (
		<Alert color="" className="h5 text-center mt-5 mb-5 lead">
			{i18next.t('keine.veranstaltungen')}
		</Alert>
	);
}

export default KeineVeranstaltungen