import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import moment from 'moment';

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const translation_en = require("./locales/en/translation.json")
const translation_de = require("./locales/de/translation.json")
const translation_ru = require("./locales/ru/translation.json")
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend) 
  
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: { translation: translation_en },
      de: { translation: translation_de },
      ru: { translation: translation_ru }
    },
    //lng: 'en',
    fallbackLng: 'de',
    debug: true,
    keySeparator: false,
        
    saveMissing: true, // send not translated keys to endpoint
  
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });
 
export default i18n;
