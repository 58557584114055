import { connect } from 'react-redux'
//import  moment  from 'moment';
import {
	addTeilnahmeRefPunkteInc, 
	addTeilnahmeRefPunkteDec, addTeilnahmeRefPunkteSet } from '../../../store/punkteMeldung/actions';
import  ReferentenpunkteComponent  from '../ReferentenpunkteComponent';

const mapStateToPropsRefPunkte = (state, ownProps) => ({
	punkte: state.punkteMeldung.addTeilnahme[ownProps.vnr].punkte_referent
})
const mapDispatchToPropsRefPunkte = (dispatch, ownProps) => ({
	increasePunkte : () => dispatch(addTeilnahmeRefPunkteInc(ownProps.vnr)),
	decreasePunkte: () => dispatch(addTeilnahmeRefPunkteDec(ownProps.vnr)),
	setPunkte : () => dispatch(addTeilnahmeRefPunkteSet(ownProps.vnr, 0))
})
export default connect(mapStateToPropsRefPunkte, mapDispatchToPropsRefPunkte)(ReferentenpunkteComponent)