import { fobiCore } from '../../FobiCore'
const regBarCodeText = /276[0-9]{16}#;#.*#;#/g
export const recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd = (text) => {
	const m = text.match(regBarCodeText)
	if(m === null) return null
	const token = m[0]
	const vnr = token.slice(0, 19)
	if(!fobiCore.isVNR(vnr)) return null
	const vnrpwd = token.slice(22, token.indexOf('#;#', 22))
	const result = { vnr, vnrpwd }
	return result
}

/**
 * recognized credentials for Veranstaltung from input text
 * @param {*} text mit entweder fobiAppBArcode- inhalt oder data im form of { vnr, pwd }
 * @returns json mit {vnr, pwd} or null is not credentials found
 */
export const getVeranstaltungsCredentials = (text) => {
	if (!text) return null;
	if(!(text instanceof String)) text = String(text);
	const fobiAppCredentials = recognizeFobiAppBarcodeAndExtractVnrAndVnrPwd(text);
	if(fobiAppCredentials) return { vnr: fobiAppCredentials.vnr, pwd: fobiAppCredentials.vnrpwd }
	try{
		const parsedValue = JSON.parse(text);
		const { vnr, pwd } = parsedValue
		if(vnr && pwd) return { vnr, pwd }
	}catch(error){
		
		// do nothing
	}
	// console.log("here");
	// bis jetzt unbekannt, suchen wir zumindest eine VNR heraus
	const splitted = text.split(/\s+/);
	const vnr = splitted.find(e => fobiCore.isVNR(e));
	if (vnr) return { vnr, pwd:"" };
	return null;
}