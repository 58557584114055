import React, { useState } from 'react';
import { /* useSelector, */ useDispatch, connect } from 'react-redux';
import { fobiCore } from '../FobiCore'
import { Label, Button } from 'reactstrap'
import { MdQrCodeScanner } from 'react-icons/md'
//import  moment  from 'moment';
import { Input} from 'reactstrap'
import { addTeilnahmeSanitizeEFNs, addTeilnahmeSetEFN, addTeilnehmer, changeTeilnahmedatum } from '../store/punkteMeldung/actions';
import { BasisPunkte } from './TeilnehmerMelden/AddTeilnehmer/BasisPunkte';
import { LernerfolgPunkte } from './TeilnehmerMelden/AddTeilnehmer/LernerfolgPunkte';
import { CSS_CLASSNAME_PANEL } from '../EIVCommons';
import  ReferentenPunkteComponent  from './TeilnehmerMelden/AddTeilnehmer/RefPunkteComponent';
import AbweichendesTeilnahmedatum from './AbweichendesTeilnahmedatum';

import moment from 'moment';
import { getMinDate, getMaxDate, isShowTeilnahmedatum } from './TeilnehmerMelden/teilnahmedatum';
import { isEFNinTeilnahmeMeldungen } from '../store/punkteMeldung/commonUtils';
import { showConfirmDialog } from '../store/confirmDialog/actions';
import { scanEFNBarcode } from '../scanbarcode/cordovascan';
import { mobileAppModus } from '../EivngCore';
// import { toast } from 'react-toastify/dist/react-toastify.umd';

import QRCodeScannerDialog from './QRCodeScannerDialog';

const KEY_ENTER = 13;

const AddTeilnehmer = (props) => {
	const { veranstaltung, efn, istBereitsGemeldet, changeEFN, addTeilnehmer, teilnahmedatum, 
		showConfirmDialog, changeTeilnahmedatum, sanitizedEFNs, 
		efnInputValid, containsMultipleEFNs, invalidEFNs } = props;
	const vnr = veranstaltung.vnr;
	let efnClassNameAddOn = (efn.length ===0 || efnInputValid)?"":" is-invalid";
	if(istBereitsGemeldet) efnClassNameAddOn+=" text-primary"
	const minDate = getMinDate(veranstaltung)
	const maxDate = getMaxDate(veranstaltung)
	const showTeilnahmedatum = isShowTeilnahmedatum(veranstaltung);

	const [showScanDialog, setShowScanDialog] = useState(false);
	const dispatch = useDispatch();
	// const efn = useSelector(state => state.punkteMeldung.addTeilnahme[vnr].efn);
	const sanitizeEFNs = () => dispatch(addTeilnahmeSanitizeEFNs(vnr));
	
	
	return(
		<div className={"container " + CSS_CLASSNAME_PANEL}>
			{ showTeilnahmedatum &&
			<div className="row">
				<div className="col-xs-12 col-sm-12 col-xl-6  p-2 ">
					<div className="input-group">
						<Label className="col-form-label">Teilnahmedatum:</Label>&nbsp;
						<AbweichendesTeilnahmedatum minDate={minDate} maxDate={maxDate} onChange={changeTeilnahmedatum} startDate={teilnahmedatum}></AbweichendesTeilnahmedatum>
					</div>
				</div>
			</div>
			}
			<div className="row justify-content-between">
				<div className="col-sm-6 col-lg-4 col-xl-3 p-2 ">
					<BasisPunkte vnr={vnr} punkte={veranstaltung.punkte_basis}/>
				</div>

			{ veranstaltung.punkte_lernerfolg > 0 && !containsMultipleEFNs &&
				<div className="col-sm-6 col-lg-4 col-xl-3 p-2">
					<LernerfolgPunkte vnr={vnr} punkte={veranstaltung.punkte_lernerfolg}/>
				</div>
			}
			<div className="col col-sm-12 col-md-6 col-lg-4 col-xl-4 p-2">
				{ !containsMultipleEFNs &&
				<div className="input-group">
					<Label className="col-form-label" title="Referent, Moderator, Wiss. Leitung">Ref/Mod/WL Pkte.:</Label>&nbsp;
					<ReferentenPunkteComponent vnr={vnr}></ReferentenPunkteComponent>
				</div>
				}
				
			</div>
			</div>
			<div className="row">
			{ mobileAppModus &&
				<div className="col-xs-12 col-sm-2 p-2">
					<Button title="EFN Barcode einscannen" className="d-none d-sm-inline"  color="secondary" outline
						onClick={ (e) => {
							scanEFNBarcode(changeEFN, showConfirmDialog)
						}}>
						<MdQrCodeScanner fontSize="1em" color="orange" ></MdQrCodeScanner>
						<span className="d-sm-none ml-2">EFN Barcode scannen</span>
					</Button>
					<Button title="EFN Barcode einscannen" className="btn-block d-sm-none"  color="secondary" outline
						onClick={ (e) => {
							scanEFNBarcode(changeEFN, showConfirmDialog)
						}}>
						<MdQrCodeScanner fontSize="1em" color="orange" ></MdQrCodeScanner>
						<span className="d-sm-none ml-2">EFN Barcode scannen</span>
					</Button>
				</div>
			}	
			{ !mobileAppModus &&
				<span className='p-2'>
				<Button title="Scan EFN Barcode" className="d-inline"  color="secondary" outline
					onClick={ (e) => { setShowScanDialog(true); }}>
					<MdQrCodeScanner fontSize="1em" color="orange" ></MdQrCodeScanner>
					<span className="d-sm-none ml-2">EFN Barcode scannen</span>
				</Button>
				<QRCodeScannerDialog isOpen={showScanDialog} veranstaltung={veranstaltung}
					toggle={() => setShowScanDialog(!showScanDialog)} 
					hideDialog={() => setShowScanDialog(false)} 
					 />
			</span>

			}
			<div className="col-sm-10 p-2">
				<div className="input-group">
					<Input valid={ efnInputValid }
						onFocus={(e) => { e.target.select() }}
						onKeyUp={(e) => {	if(e.keyCode === KEY_ENTER && efnInputValid) addTeilnehmer() } }
						className={"form-control" + efnClassNameAddOn} required={true} type="text" autoFocus = {!mobileAppModus}
						onChange={(e) => changeEFN(e.target.value)} value={efn} title='Eingabe von mehreren EFNs möglich'
						placeholder="EFN(s)"></Input>
					&nbsp;<button type="button" disabled={!fobiCore.validateMultipleEFNs(efn)} onClick={ () => {addTeilnehmer()}} className="btn btn-secondary">Hinzufügen</button>
					{istBereitsGemeldet &&
						<div className="valid-feedback text-primary">Für diese EFN gibt es bereits Punktemeldung(en). Ist es eine Korrektur?</div>
					}
					{containsMultipleEFNs &&
						<div className={invalidEFNs?"invalid-feedback  text-danger":"valid-feedback"}>
						{ invalidEFNs &&
							<span>Das sind keine EFNs: { invalidEFNs }</span> 
						}
						{ efn.length !== sanitizedEFNs.length &&
							<Button title="ungültige Elemente entfernen" className="mx-2" onClick={sanitizeEFNs} color="info" outline size="sm">bereinigen</Button>
						}
						
						</div>				 
					}
				</div>
			</div>
			</div>
			{props.children}
		</div>
	)
}

const mapStateToProps = (state, ownProps) => { 
	const efnInput = state.punkteMeldung.addTeilnahme[ownProps.veranstaltung.vnr].efn;
	const sanitizesArrayOfEFNs = fobiCore.extractEFNsToArray(efnInput);
	return {
			efn: efnInput,
			efnInputValid: fobiCore.validateMultipleEFNs(efnInput),
			containsMultipleEFNs: fobiCore.containsMultipleEFNs(efnInput),
			sanitizedEFNs: sanitizesArrayOfEFNs?sanitizesArrayOfEFNs.join(' '):"",
			invalidEFNs: fobiCore.extractInvalidEFNsToArray(efnInput).reduce( (a, efn) => a + efn + "; ", ""),
			teilnahmedatum: state.punkteMeldung.addTeilnahme[ownProps.veranstaltung.vnr].teilnahmedatum,
			istBereitsGemeldet : isEFNinTeilnahmeMeldungen(
				state.punkteMeldung.addTeilnahme[ownProps.veranstaltung.vnr].efn,
				ownProps.veranstaltung.vnr, 
				state)
	}
}

const mapDispatchToProp = (dispatch, ownProps) => ({
	
	changeEFN: efn => dispatch(addTeilnahmeSetEFN(ownProps.veranstaltung.vnr, efn)),
	showConfirmDialog: (title, message, onConfirm) => dispatch(showConfirmDialog(title, message, onConfirm)),
	addTeilnehmer: (vnr) => dispatch(addTeilnehmer(ownProps.veranstaltung.vnr, ownProps.addTeilnahmeState)),
	changeTeilnahmedatum:  (teilnahmedatum) => {
		if(!teilnahmedatum) teilnahmedatum = moment(ownProps.veranstaltung.beginn).toDate()
		const minDate = getMinDate(ownProps.veranstaltung)
		return dispatch(changeTeilnahmedatum(ownProps.veranstaltung.vnr, teilnahmedatum===null?minDate:moment(teilnahmedatum.getTime()).endOf('day').valueOf()))
	}
})
export default connect(mapStateToProps, mapDispatchToProp)(AddTeilnehmer) 
