import { BACKEND_BASE_URL, BACKEND_KAMMERDIENER_PREFIX, getHeaderWithBearerAutorization } from "../../EivngCore"
import { fetchPunktemeldungen } from "./fetchPunktemeldungen"
import { isPreventTeilnahmedatumMeldung } from "../../components/TeilnehmerMelden/teilnahmedatum"
import { fetchVeranstaltungOnly } from "../veranstaltungen/actions"
import moment from 'moment';

export const  TEILNEHMER_MELDEN_REQUESTED = "TEILNEHMER_MELDEN_REQUESTED"
export const  REMOVE_ALL_SEIZED_TEILNEHMER = "REMOVE_ALL_SEIZED_TEILNEHMER"
export const  TEILNEHMER_MELDEN_COMPLETED = "TEILNEHMER_MELDEN_COMPLETED"
export const  TEILNEHMER_MELDEN_FAILED = "TEILNEHMER_MELDEN_FAILED"

export const  TEILNEHMER_MELDEN_PUSH_COMPLETED = "TEILNEHMER_MELDEN_PUSH_COMPLETED"

export function removeAllSeized(vnr, erfassteEFNs){
  return {type: REMOVE_ALL_SEIZED_TEILNEHMER, vnr, efns: erfassteEFNs}
}
export function meldeTeilnehmerStarted(vnr){
  return {type: TEILNEHMER_MELDEN_REQUESTED, vnr}
}
export function meldeTeilnehmerCompleted(vnr){
  return {type: TEILNEHMER_MELDEN_COMPLETED, vnr}
}
export function meldeTeilnehmerFailed(vnr, status){
  return {type: TEILNEHMER_MELDEN_FAILED, vnr, payload: status}
}
export function pushTeilnahmeCompleted(vnr, efn){
  return {type: TEILNEHMER_MELDEN_PUSH_COMPLETED, vnr, efn}
}

function peekTeilnahmeToSend(state, vnr){
  const erfassteTeilnehmer = state.punkteMeldung.erfassteTeilnahmen[vnr]
  if(erfassteTeilnehmer === undefined || erfassteTeilnehmer === null) return null
  const teilnehmer = Object.values(erfassteTeilnehmer)
  if (teilnehmer.length === 0) return null
  return {...teilnehmer[0]}
}
export  function meldeErfassteTeilnahmen(vnr) {
  return async (dispatch, getState) => {
  
    dispatch(meldeTeilnehmerStarted(vnr));
    console.debug('starte Übermittlung der Teilnehmermeldungen für VNR', vnr);
  
    const veranstaltung = getState().veranstaltungen[vnr]
    const bearer = veranstaltung.bearer
    const suppressTeilnahmeDatum = isPreventTeilnahmedatumMeldung(veranstaltung)
    try{
      let counter = 0;
      let melde = true
      while (melde){
        const teilnahme = peekTeilnahmeToSend(getState(), vnr)
        if(teilnahme === null) break;
        if(suppressTeilnahmeDatum) {
          teilnahme.teilnahmedatum = null
          console.log("Teilnahmedatum unterdrucken", teilnahme)
        }else{
          teilnahme.teilnahmedatum = moment(teilnahme.teilnahmedatum).toISOString();
        }
        delete teilnahme["addedTimestamp"]
                
        const headers = getHeaderWithBearerAutorization(bearer);
        const response = await fetch(`${BACKEND_BASE_URL}${BACKEND_KAMMERDIENER_PREFIX}/fobi/veranstalter/push_teilnahme`,
        
        {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(teilnahme),
          headers: headers
        });
        const jsonData = await response.json()
        console.log("Server responsed", jsonData)
        switch(response.status){
          case 200:
            dispatch(pushTeilnahmeCompleted(vnr, teilnahme.efn));
            counter++
            console.debug("melde Teilnahme", counter, teilnahme.efn)
            break;
          case 403:
            dispatch(meldeTeilnehmerFailed(vnr, response.status));
            dispatch(fetchVeranstaltungOnly(bearer));
            melde = false        
            break;
          default:
            dispatch(meldeTeilnehmerFailed(vnr, response.status));
            melde = false        
        }
      }
      dispatch(meldeTeilnehmerCompleted(vnr));
    }catch(e){
      console.error('Error during Teilnehmermeldung für VNR', vnr, e);
      dispatch(meldeTeilnehmerFailed(vnr));
    }finally{
      dispatch(fetchPunktemeldungen(vnr, bearer))
    }
  
  }
}