export const CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION = 'CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION'
export const CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION_ERROR = 'CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION_ERROR'
export const CORDOVA_PUSH_NOTIFICATIONS_ON_NOTIFICATION = 'CORDOVA_PUSH_NOTIFICATIONS_ON_NOTIFICATION'
export const CORDOVA_PUSH_NOTIFICATIONS_REGISTRATIONID_CHANGED = 'CORDOVA_PUSH_NOTIFICATIONS_REGISTRATIONID_CHANGED'

// Action creator
export const onRegistrationAction = (data) => {
	return { type: CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION, payload: data}
}
export const onNotificationAction = (data) => {
	return { type: CORDOVA_PUSH_NOTIFICATIONS_ON_NOTIFICATION, payload: data}
}
export const onRegistrationErrorAction = (error) => {
	return { type: CORDOVA_PUSH_NOTIFICATIONS_ON_REGISTRATION_ERROR, payload: error}
}
export const registrationIdChanged = () => {
	return { type: CORDOVA_PUSH_NOTIFICATIONS_REGISTRATIONID_CHANGED}
}