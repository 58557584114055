import React from 'react';
import { connect } from 'react-redux'

const FetchingInProgress = (props) => {
	const { fetchCounter } = props;
	if(fetchCounter === 0) return null;
	return(
	// <Progress value={100} />
	<div className="GNVPVGB-S-a">
		 <div className="GNVPVGB-fb-a">
			 <div className="GNVPVGB-fb-b"></div> 
			 <div className="GNVPVGB-fb-c"></div>
			</div> 
	</div>
	);
}

const mapStateToProps = state => {
  return { 
		fetchCounter: state.fetchCounter
	}
}

export default connect(mapStateToProps)(FetchingInProgress) 