import React from 'react';

import {connect} from 'react-redux'
import VeranstaltungsItem  from './VeranstaltungsItem';
import KeineVeranstaltungen from './KeineVeranstaltungen';

const veranstaltungenCompare = ( a, b ) => {
  if ( a.beginn < b.beginn ){
    return 1;
  }
  if ( a.beginn > b.beginn ){
    return -1;
  }
  return 0;
}

const Veranstaltungsliste = (props) => {
	const veranstaltungen = Object.values(props.veranstaltungsData).sort(veranstaltungenCompare);
	const {punkteMeldung} = props
	const keineVeranstaltungen = undefined === props.veranstaltungsData || Object.keys(props.veranstaltungsData).length === 0;
	if(keineVeranstaltungen) return (
		<KeineVeranstaltungen />
	)
  return (<ul className="list-group">
		{
			veranstaltungen.map((veranstaltung) =>  (
				<li className="list-group-item" key={veranstaltung.vnr}>
				<VeranstaltungsItem countErfasste={ punkteMeldung.erfassteTeilnahmen[veranstaltung.vnr]===undefined?
						0:Object.keys(punkteMeldung.erfassteTeilnahmen[veranstaltung.vnr]).length}
        		data={veranstaltung} />
				</li>))
		}
		
  </ul>);
}

const mapStateToProps = function(state) {
  return { 
		veranstaltungsData: state.veranstaltungen,
		punkteMeldung: state.punkteMeldung
	}
}

export default connect(mapStateToProps)(Veranstaltungsliste) 



				

