import React from 'react'
import { Alert } from 'reactstrap'
import EFNSetupEingabe from './EFNSetupEingabeConnector'
import { connect } from 'react-redux'
import { keinPunktekontoHinweis } from '../EIVCommons'


export const Introduction = (props) => {
	return (
		<Alert color="" className="h5 text-center mt-5 mb-5 lead">
		<p>Als Teilnehmer mit EFN können Sie über die für Sie erfolgten Punktemeldungen informiert werden.</p>
	  {keinPunktekontoHinweis}
		</Alert>
	)
}

const Infoquelle = (props) => {
	return (
		<Alert color="light" className="">
		<p>Zur Aktivierung benötigen Sie zusätzlich zur Ihrer EFN ein Authorisierungs- Kennwort. 
			Ihre zuständige Landesärztekammer versorgt Sie damit, sofern sie diese Dienstleistung anbietet.</p>
		</Alert>
	)
}

const EFNSetupPage = (props) => {
	const {efnSetupCompleted = false } = props
	if(efnSetupCompleted) return null;
	return(
		<>
		<Introduction/>
		<EFNSetupEingabe/>
		<Infoquelle/>
		</>	
	)
}

const mapStateToProps = (state, ownProps) => (
	{
		efnSetupCompleted : state.efnHolder.efnSetupCompleted
	}
)

export default connect(mapStateToProps)(EFNSetupPage);