import { BACKEND_BASE_URL, BACKEND_KAMMERDIENER_PREFIX } from "../../EivngCore"
import { fetchPunktemeldungen } from "../punkteMeldung/fetchPunktemeldungen"
import { addVeranstaltungShowAlert } from "../alerts/actions"
import * as fetchUtil from "../../common/fetchUtils"
import { messageOn403, messageOn403JWT } from "../../components/AddVeranstaltungAlert"
import { FAILED_TO_FETCH_ERROR, INTERNET_CONNECTION_APPEARS_TO_BE_OFFLINE } from "../../CommonUtils";
import jwt_decode from "jwt-decode";

export const  DELETE_VERANSTALTUNG = 'DELETE_VERANSTALTUNG'

export const  FETCH_VERANSTALTUNG_REQUEST = "FETCH_VERANSTALTUNG_REQUEST"
export const  FETCH_VERANSTALTUNG_REQUEST_FAILURE = "FETCH_VERANSTALTUNG_REQUEST_FAILURE"
export const  FETCH_VERANSTALTUNG_REQUEST_SUCCESS = "FETCH_VERANSTALTUNG_REQUEST_SUCCESS"

export const  FETCH_PUNKTEMELDUNGEN_REQUEST = "FETCH_PUNKTEMELDUNGEN_REQUEST"
export const  FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE = "FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE"
export const  FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS = "FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS"

export const  ADD_VERANSTALTUNG_PANEL_SET_VNR = "ADD_VERANSTALTUNG_PANEL_SET_VNR"
export const  ADD_VERANSTALTUNG_PANEL_SET_VNRPWD = "ADD_VERANSTALTUNG_PANEL_SET_VNRPWD"
export const  ADD_VERANSTALTUNG_PANEL_SET_VNR_CREDENTIALS = "ADD_VERANSTALTUNG_PANEL_SET_VNR_CREDENTIALS"
export const  ADD_VERANSTALTUNG_PANEL_SET_COLLAPSED = "ADD_VERANSTALTUNG_PANEL_SET_COLLAPSED"

export function requestFetchPunktemeldungen(vnr){
  return {type: FETCH_PUNKTEMELDUNGEN_REQUEST, vnr}
}
export function setShowAddVeranslatungsPanel(flag){
  return {type: ADD_VERANSTALTUNG_PANEL_SET_COLLAPSED, payload: flag}
}
export function receiveFetchedPunktemeldungen(vnr, punktemeldungen){
  return {type: FETCH_PUNKTEMELDUNGEN_REQUEST_SUCCESS, vnr, punktemeldungen}
}
export function fetchPunktemeldungenFailed(vnr, error){
  return { type: FETCH_PUNKTEMELDUNGEN_REQUEST_FAILURE, payload: error, vnr }
}
export function receiveFetchedVeranstaltung(veranstaltung) {
  return { type: FETCH_VERANSTALTUNG_REQUEST_SUCCESS, veranstaltung }
}
export function requestFetchVeranstaltung() {
  return { type: FETCH_VERANSTALTUNG_REQUEST }
}
export function deleteVeranstaltung(vnr) {
  return { type: DELETE_VERANSTALTUNG, vnr }
}
export function addVeranstaltungPanelSetVNR(vnr) {
  return { type: ADD_VERANSTALTUNG_PANEL_SET_VNR, payload: vnr }
}
export function addVeranstaltungPanelSetVNRPwd(vnrpwd) {
  return { type: ADD_VERANSTALTUNG_PANEL_SET_VNRPWD, payload: vnrpwd };
}
export function addVeranstaltungPanelSetVNRCredentials(vnr, pwd) {
  return { type: ADD_VERANSTALTUNG_PANEL_SET_VNR_CREDENTIALS, payload: {vnr, pwd } };
}
function fetchVeranstaltungFailed(error){
  return { type: FETCH_VERANSTALTUNG_REQUEST_FAILURE, payload: error };
}

export function fetchVeranstaltungWithJWT(jwt) {
  return async dispatch => {
  
    dispatch(requestFetchVeranstaltung());

    console.log('being load Veranstaltungsdata from server with jwt token');
    
    try{  
      const authURL = `${BACKEND_BASE_URL}/fobi/veranstalter-auth/jwt` 
      const result = await fetchUtil.fetchJsonWithJWT(jwt, authURL, "GET");
      if(result.isLeft){
        const statusCode = fetchUtil.getStatusOnLeft(result);
        switch(statusCode){
          case 403:
            dispatch(addVeranstaltungShowAlert(messageOn403JWT)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
            dispatch(fetchVeranstaltungFailed(403));
            break;
          case 401:
            dispatch(addVeranstaltungShowAlert(messageOn403JWT)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
            dispatch(fetchVeranstaltungFailed(401));
            break;
          default:
            dispatch(fetchVeranstaltungFailed(statusCode));
        }
        return;
      }
    const bearer = result.value.json.jwt;
    const vnr = jwt_decode(bearer).sub;

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${bearer}`);
      const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/veranstalter/veranstaltung",
      {
      method: 'get',
      credentials: 'include',
      headers: headers			
      })
      const status = response.status
      console.debug(status)
      switch(status){
        case 200:
            const jsonData = await response.json()
            console.debug('Fetched Data:', jsonData);
            jsonData.bearer = bearer;
            dispatch(receiveFetchedVeranstaltung(jsonData));
            dispatch(fetchPunktemeldungen(vnr, bearer));
            dispatch(setShowAddVeranslatungsPanel(false));
            break;
        case 403:
          dispatch(addVeranstaltungShowAlert(messageOn403JWT)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
          dispatch(fetchVeranstaltungFailed(403));
          break;
        case 401:
          dispatch(addVeranstaltungShowAlert(messageOn403JWT)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
          dispatch(fetchVeranstaltungFailed(401));
          break;
        default:
          dispatch(fetchVeranstaltungFailed(status));
      }
      
    }catch(err){
      console.error('Veranstaltung fetch error', err);
      dispatch(fetchVeranstaltungFailed(err));
      const strError = String(err)
      if(strError === FAILED_TO_FETCH_ERROR || strError === INTERNET_CONNECTION_APPEARS_TO_BE_OFFLINE){
        dispatch(addVeranstaltungShowAlert("Verbindung zum Server konnte nicht aufgebaut werden. Ist die Internet- Verbindung verfügbar ?"))
      }else{
        dispatch(addVeranstaltungShowAlert("Veranstaltungsdaten konnten nicht geladen werden: " + err))
      }
    }
  }
}

export function fetchVeranstaltung(vnr, vnrpwd) {
  return async dispatch => {
  
    dispatch(requestFetchVeranstaltung());

    console.log('being load Veranstaltungsdata from server');
    
    try{  
      const authURL = `${BACKEND_BASE_URL}/fobi/veranstalter-auth/jwt` 
      const result = await fetchUtil.fetchJsonWithBasicAuth(vnr, vnrpwd, authURL, "GET");
      if(result.isLeft){
        const statusCode = fetchUtil.getStatusOnLeft(result);
        switch(statusCode){
          case 403:
            dispatch(addVeranstaltungShowAlert(messageOn403)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
            dispatch(fetchVeranstaltungFailed(403));
            break;
          case 401:
            dispatch(addVeranstaltungShowAlert(messageOn403)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
            dispatch(fetchVeranstaltungFailed(401));
            break;
          default:
            dispatch(fetchVeranstaltungFailed(statusCode));
        }
        return;
      }
    const bearer = result.value.json.jwt;

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${bearer}`);
      const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/veranstalter/veranstaltung",
      {
      method: 'get',
      credentials: 'include',
      headers: headers			
      })
      const status = response.status
      console.debug(status)
      switch(status){
        case 200:
            const jsonData = await response.json()
            console.debug('Fetched Data:', jsonData);
            jsonData.bearer = bearer;
            dispatch(receiveFetchedVeranstaltung(jsonData));
            dispatch(fetchPunktemeldungen(vnr, bearer))
            break;
        case 403:
          dispatch(addVeranstaltungShowAlert(messageOn403)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
          dispatch(fetchVeranstaltungFailed(403));
          break;
        case 401:
          dispatch(addVeranstaltungShowAlert(messageOn403)) // todo process it in alert reducer on FETCH_VERANSTALTUNG_REQUEST_FAILURE with 403 argument
          dispatch(fetchVeranstaltungFailed(401));
          break;
        default:
          dispatch(fetchVeranstaltungFailed(status));
      }
      
    }catch(err){
      console.error('Veranstaltung fetch error', err);
      dispatch(fetchVeranstaltungFailed(err));
      const strError = String(err)
      if(strError === FAILED_TO_FETCH_ERROR || strError === INTERNET_CONNECTION_APPEARS_TO_BE_OFFLINE){
        dispatch(addVeranstaltungShowAlert("Verbindung zum Server konnte nicht aufgebaut werden. Ist die Internet- Verbindung verfügbar ?"))
      }else{
        dispatch(addVeranstaltungShowAlert("Veranstaltungsdaten konnten nicht geladen werden: " + err))
      }
    }
  }
}

export function fetchVeranstaltungOnly(bearer) {
  return async dispatch => {
  
    dispatch(requestFetchVeranstaltung());
    //  ("Authorization", "Basic " + btoa(username + ":" + password));
    console.debug('being load Veranstaltungsdata from server');
    
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Authorization', `Bearer ${bearer}`);
    try{  
      const response = await fetch(BACKEND_BASE_URL + BACKEND_KAMMERDIENER_PREFIX + "/fobi/veranstalter/veranstaltung",
      {
      method: 'get',
      credentials: 'include',
      headers: headers			
      })
      const status = response.status
      console.debug(status)
      switch(status){
        case 200:
            const jsonData = await response.json()
            console.debug('Fetched Data:', jsonData);
            jsonData.bearer = bearer;
            dispatch(receiveFetchedVeranstaltung(jsonData));
            break;
        // case 403:
        //   dispatch(fetchVeranstaltungFailed(403));
        //   break;
        default:
          dispatch(fetchVeranstaltungFailed(status));
      }
      
    }catch(err){
      console.error('Veranstaltung Fetch Error :-S', err);
      dispatch(fetchVeranstaltungFailed(err));
    }
  }
}
