export const preStartStatePreparation = (state) => {
	/* Reset fetch counter. Der Wert kann > 0 sein, wenn während schlechten Verbindung die Application geschlossen wird. 
	Deswegen muss der Wert immer beim Start auf 0 zurückgesetzt werden
	*/
	state.fetchCounter = 0
	/* check querystring and set navigation to home if parameter vnr found */
	const params = new URLSearchParams(window.location.search);
	const vnr = params.get('vnr'); // Ersetzen Sie 'myParam' durch den Namen Ihres Parameters
	console.log(window.location.search);
	console.log(vnr);
	if(vnr){
		const vnrLoaded = state && state.veranstaltungen[vnr];
		if(vnrLoaded){
			state.appNavigation.currentScreen = "veranstaltungPage";
		}else{
			state.appNavigation.currentScreen = "startPage";
		}
		state.appNavigation.selectedVNR = vnr;
	}

	/*
appNavigation: 
currentScreen:"veranstalterPage"
displayDialogQrcodeLinkToApp:false
saveStateToLocalStorage:true
selectedVNR:"2760602023000160003"
	*/
}
