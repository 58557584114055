import React from 'react';
import { connect } from 'react-redux'
import { addTeilnahmeTogglePunkteLernerfolg } from '../../../store/punkteMeldung/actions';
import { FlagComponent } from '../../FlagComponent';

const mapStateToPropsPunkteLernerfolg = (state, ownProps) => ({ 
	flag : state.punkteMeldung.addTeilnahme[ownProps.vnr].punkte_lernerfolg_flag
})
const mapDispatchToPropPunkteLernerfolg = (dispatch, ownProps) => ({
	onClick : () => dispatch(addTeilnahmeTogglePunkteLernerfolg(ownProps.vnr))
})
const FlagLernerfolgComponent = connect(mapStateToPropsPunkteLernerfolg, mapDispatchToPropPunkteLernerfolg)(FlagComponent)
export const LernerfolgPunkte = (props) => {
	return(
		<div className="input-group inline-flex">
			<FlagLernerfolgComponent vnr={props.vnr}/>&nbsp;
			<span className="col-form-label">Lernerfolg ({props.punkte}  Pkt.)</span>
			
		</div>
	)
}